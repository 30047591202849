import { InputLabelProps, MenuItem, Stack, SxProps, Typography } from '@mui/material';
import { Task } from '@operto/tasks-shared';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { ICompany } from 'company/companyType';
import { getCurrentCompany } from 'company/state/companySelectors';
import format from 'date-fns/format';
import { FilterType } from 'guest/guestType';
import { getGuests } from 'guest/state/guestActions';
import { guestByFilter } from 'guest/state/guestSelectors';
import { DATE_FORMAT } from 'helper/date';
import useMessaging from 'hooks/useMessaging';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { memberSelector } from 'member/state/memberSelectors';
import { PropertyFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getReservations } from 'reservation/state/reservationActions';
import { getAllReservationsByIds } from 'reservation/state/reservationSelectors';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userSelector } from 'user/state/userSelectors';
import TasksFieldSelector from './TasksFieldSelector';
import useTasks from './useTasks';

const rootContainerStyles: SxProps = {
  borderLeft: '1px solid var(--light-other-outlined-border-23-p, rgba(8, 22, 62, 0.23))',
  position: 'absolute',
  right: 0,
  overflowY: 'scroll',
  maxWidth: '500px',
  flexDirection: 'column',
};

export interface TaskDetailsProps {
  handleClose: () => void;
  taskId?: string;
  onTaskUpdate?: (task: Task) => void;
}

export interface TaskOptions {
  id: string;
  name: string;
}

const labelOptions: TaskOptions[] = [
  { id: 'housekeeping', name: 'Housekeeping' },
  { id: 'accounting', name: 'Accounting' },
  { id: 'front desk', name: 'Front Desk' },
  { id: 'valet', name: 'Valet' },
  { id: 'maintenance', name: 'Maintenance' },
  { id: 'room service', name: 'Room Service' },
  { id: 'other', name: 'Other' },
];

const statusOptions: TaskOptions[] = [
  { id: 'requested', name: 'Requested' },
  { id: 'reviewed', name: 'Reviewed' },
  { id: 'scheduled', name: 'Scheduled' },
  { id: 'in progress', name: 'In Progress' },
  { id: 'completed', name: 'Completed' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'closed', name: 'Closed' },
  { id: 'declined', name: 'Declined' },
];

const priorityOptions: TaskOptions[] = [
  { id: 'none', name: 'None' },
  { id: 'low', name: 'Low' },
  { id: 'medium', name: 'Medium' },
  { id: 'high', name: 'High' },
];

const taskDetailsAsteriskStyles: InputLabelProps = {
  shrink: true,
  sx: { '.MuiInputLabel-asterisk': { color: '#D32F2F' } },
};

const TITLE_MAX_LENGTH = 200;
const DESCRIPTION_MAX_LENGTH = 1000;

const TasksDetails = ({ handleClose, taskId, onTaskUpdate }: TaskDetailsProps) => {
  const { t } = useTranslation();
  const { snackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const { createChannel, addMembersToChannel } = useMessaging();
  const { getTask, createTask, updateTask } = useTasks({
    refetchOnWindowFocus: false,
    fetchOnMount: false,
  });

  const minDueDate = format(new Date(), DATE_FORMAT);
  const members = useAppSelector(memberSelector());
  const loggedInMember = useAppSelector(userSelector());
  const currentUserId = loggedInMember?.getStreamUser?.id;
  const company: ICompany = useAppSelector(getCurrentCompany());
  const formDefaultState = useMemo(
    () => ({
      dueDateLocalized: minDueDate,
      priority: 'none',
    }),
    [minDueDate],
  );

  const reservationOptions = useAppSelector(getAllReservationsByIds());
  const { guests: guestOptions } = useAppSelector(guestByFilter(FilterType.ALL_GUESTS));
  const { properties: propertyOptions } = useAppSelector(
    getPropertiesByFilter({
      filterType: PropertyFilterType.ALL_PROPERTIES,
    }),
  );

  const [localTask, setLocalTask] = useState<Task>();
  const [createdAt, setCreatedAt] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setShowLoading] = useState(true);

  const clientHeight = document.getElementById('page-container')?.clientHeight;
  const isNew = taskId === undefined;
  const { data: task, isLoading: isTaskLoading } = getTask(taskId) ?? {};

  const { handleSubmit, watch, setValue, reset, clearErrors, control } = useForm<Task>({
    mode: 'all',
    defaultValues: formDefaultState,
    values: localTask,
  });
  const formState = useFormState({ control });
  // workaround, since isDirty upon change on the form happens, but dirtyFields object is empty
  const isFormChanged = Object.keys(formState.dirtyFields).length > 0;

  const titleValue = watch('title');
  const titleLengthText = titleValue && `${titleValue?.length} / ${TITLE_MAX_LENGTH}`;

  const descriptionValue = watch('description');
  const descriptionLengthText =
    descriptionValue && `${descriptionValue?.length} / ${DESCRIPTION_MAX_LENGTH}`;

  const labelValue = watch('type');
  const label = labelOptions.find(option => option.id === labelValue);

  const statusValue = watch('status');
  const status = statusOptions.find(option => option.id === statusValue);

  const memberValue = watch('assigneeId');
  const member = members.find(option => option.id === +memberValue);

  const compareNumbers = (a: number, b: number) => a - b;
  const compareStrings = (a: string, b: string) => a.localeCompare(b);

  // set up logic to auto-populate reservation, property & guest:
  // include id options that are shared between reservations and guests ONLY
  const reservationIds = new Set(reservationOptions.map(res => res.id));
  const guestIds = new Set(guestOptions.map(guest => guest.id));
  const sharedIds = new Set([...reservationIds].filter(x => guestIds.has(x)));

  // filter, then sort in place
  const sortedReservations = reservationOptions.filter(res => sharedIds.has(res.id));
  const sortedGuests = guestOptions.filter(guest => sharedIds.has(guest.id));

  sortedReservations.sort((a, b) => compareNumbers(a?.id, b?.id));
  sortedGuests.sort((a, b) => compareStrings(a?.name || '', b?.name || ''));
  propertyOptions.sort((a, b) => compareStrings(a?.name || '', b?.name || ''));

  // find reservation and guest based on form values to compare against
  const reservationIdValue = watch('reservationId');
  const guestIdValue = watch('guestId');
  const propertyIdValue = watch('unit');
  const reservation = sortedReservations.find(res => res.id === +reservationIdValue);
  const guest = sortedGuests.find(
    guest => guest.id === +guestIdValue || guest.id === reservation?.id,
  );
  const property = propertyOptions.find(
    property => property.id === +propertyIdValue || property.id === reservation?.property_id,
  );

  const submitTask = async (data: Task) => {
    setIsSaving(true);

    let copyData: Task = JSON.parse(JSON.stringify(data));
    delete copyData.guestId; // Temp solution to avoid errors in BE, in future ticket we are fixing getting guest

    const memberIds: string[] = [];
    if (currentUserId) {
      memberIds.push(currentUserId);
    }

    if (copyData.reservationId) {
      copyData.reservationId = String(copyData.reservationId);
    }

    if (copyData.unit) {
      copyData.unit = String(copyData.unit);
    }

    if (copyData.assigneeId) {
      copyData.assigneeId = String(copyData.assigneeId);
      memberIds.push(`mid-${copyData.assigneeId}`);
    }

    if (copyData.priority === 'none') {
      copyData.priority = undefined;
    }

    if (isNew) {
      try {
        copyData = await createTask(copyData);
        await createChannel({
          channelId: `cid${company?.id}-tid${copyData.id}`,
          memberIds: [...new Set(memberIds)],
          channelName: copyData.title,
          channelType: 'tasks',
        });
      } catch (error) {
        logger.error('Error creating task', error);
        setIsSaving(false);
        return;
      }
    } else {
      try {
        await updateTask(copyData);
        await addMembersToChannel({
          channelId: `cid${company?.id}-tid${copyData.id}`,
          memberIds: memberIds,
        });
      } catch (error) {
        logger.error('Error updating task', error);
        setIsSaving(false);
        return;
      }
    }

    setLocalTask(copyData);
    reset(copyData);
    onTaskUpdate?.(copyData);
    snackbar(t(isNew ? 'task_added' : 'changes_saved'));
    setIsSaving(false);
  };

  useEffect(() => {
    const loadResources = async () => {
      setShowLoading(true);
      dispatch(getReservations());
      dispatch(getGuests({ filterType: FilterType.ALL_GUESTS }));
      dispatch(getProperties());
    };

    loadResources();
  }, [dispatch]);

  useEffect(() => {
    const isNewTaskFormReady = isNew && guestOptions && reservationOptions && propertyOptions;
    const isEditTaskFormReady =
      !isNew &&
      !isTaskLoading &&
      (reservation?.id ? guest && property : guestOptions && reservationOptions && propertyOptions);
    if (isNewTaskFormReady || isEditTaskFormReady) {
      setShowLoading(false);
      clearErrors();
    }
  }, [
    guest,
    reservation,
    property,
    isTaskLoading,
    isNew,
    guestOptions,
    reservationOptions,
    propertyOptions,
    clearErrors,
  ]);

  // auto-populate reservation, property & guest fields based on selection
  useEffect(() => {
    if (
      reservationIdValue &&
      reservationIdValue !== 'none' &&
      reservation?.id &&
      reservation?.property_id
    ) {
      // internally use reservationId in the guestId field to better link the two
      setValue('guestId', reservation.id);
      setValue('unit', reservation.property_id);
    }
  }, [reservationIdValue, reservation?.id, reservation?.property_id, setValue]);

  useEffect(() => {
    if (guestIdValue && guestIdValue !== 'none' && guest?.id && guest?.property_id) {
      setValue('reservationId', guest?.id);
      setValue('unit', guest?.property_id);
    }
  }, [guestIdValue, guest?.id, guest?.property_id, setValue]);

  useEffect(() => {
    setShowLoading(true);
    if (task) {
      const taskData = { ...task, priority: task.priority ?? 'none' };
      setLocalTask(taskData);
    } else {
      setLocalTask(null);
    }
  }, [task, reset]);

  useEffect(() => {
    if (isNew) {
      reset(formDefaultState);
    } else {
      if (localTask?.createdAt) {
        setCreatedAt(format(new Date(localTask.createdAt), DATE_FORMAT));
      }
      reset(localTask);
    }
  }, [localTask, isNew, minDueDate, reset, formDefaultState]);

  return (
    <Stack sx={{ ...rootContainerStyles, maxHeight: clientHeight + 'px' }}>
      <LoadingContainer loading={isLoading}>
        <FormContainer
          title={isNew ? t('task_sidebar_create_task') : t('task_sidebar_edit_task')}
          onClose={handleClose}
          dirty={isFormChanged}
          onSubmit={handleSubmit(submitTask)}
          submitButtonTitle={t('save')}
          submitButtonIcon={null}
          submitButtonSx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: '15px' }}
          loading={isSaving}
          useConfirmLeaveDialogPopup={true}
        >
          <AddCard title={t('guest')}>
            <TasksFieldSelector
              value={reservation}
              options={sortedReservations}
              control={control}
              isEnabled={isNew}
              fieldName='reservationId'
              fieldLabel={t('reservation')}
            />

            <TasksFieldSelector
              value={property}
              options={propertyOptions}
              control={control}
              isEnabled={false}
              fieldName='unit'
              fieldLabel={t('room')}
            />

            <TasksFieldSelector
              value={guest}
              options={sortedGuests}
              control={control}
              isEnabled={isNew}
              fieldName='guestId'
              fieldLabel={t('guest')}
            />
          </AddCard>

          <AddCard title='Task'>
            <FormTextField
              rules={{
                required: 'Title is required',
                validate: value => value?.length <= TITLE_MAX_LENGTH || titleLengthText,
              }}
              required
              field='title'
              label='Title'
              control={control}
              helperText={titleLengthText}
              FormHelperTextProps={{ sx: { textAlign: titleValue && 'right' } }}
              InputLabelProps={taskDetailsAsteriskStyles}
            />

            <TasksFieldSelector
              value={label}
              options={labelOptions}
              control={control}
              fieldName='type'
              fieldLabel='Label'
              customProps={{
                InputLabelProps: taskDetailsAsteriskStyles,
                required: true,
                rules: {
                  required: 'Label is required',
                },
              }}
            />

            <TasksFieldSelector
              value={status}
              options={statusOptions}
              control={control}
              fieldName='status'
              fieldLabel='Status'
            />
            <FormTextField
              field='dueDateLocalized'
              label='Due on'
              type='datetime-local'
              control={control}
              inputProps={{ min: minDueDate }}
            />
            <FormTextField select field='priority' label='Priority' control={control}>
              {priorityOptions.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </FormTextField>
            <FormTextField
              rules={{
                validate: value => value?.length <= DESCRIPTION_MAX_LENGTH || descriptionLengthText,
              }}
              multiline
              rows={8}
              field='description'
              label='Description'
              control={control}
              helperText={descriptionLengthText}
              FormHelperTextProps={{ sx: { textAlign: descriptionValue && 'right' } }}
            />
          </AddCard>

          <AddCard title='Assignee'>
            <TasksFieldSelector
              value={member}
              options={members}
              control={control}
              fieldName='assigneeId'
              fieldLabel='Assignee'
              showMember={true}
            />
          </AddCard>

          {!isNew && (
            <>
              <Typography variant='subtitle-sm-600'>{t('created_on')}</Typography>
              <Typography variant='body1'>{createdAt}</Typography>
            </>
          )}
        </FormContainer>
      </LoadingContainer>
    </Stack>
  );
};

export default TasksDetails;
