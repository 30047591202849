import { trpc } from '@operto/trpc-client';

export default function useCopilot() {
  const createSuggestionsMutation = trpc.aiCopilotMessengerService?.createSuggestions.useMutation();

  const createSuggestions = async (information: createSuggestionsProps) => {
    return createSuggestionsMutation.mutateAsync(information);
  };

  return { createSuggestions };
}

type createSuggestionsProps = {
  reservationInfo: string;
  guidesInfo: string;
  conversation: string;
};
