import { Box } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import {
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery,
  usePostAccessCompatibilityAddSeamAccountMutation,
  usePostAccessCompatibilityOnboardingGenerateLinkMutation,
} from 'services/novaApi';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';

const boxCenteredStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '20px',
  width: '100%',
};

const iframeStyle = {
  borderRadius: '28px',
  border: 'none',
  minHeight: '600px',
  height: '702.533px',
  width: '400px',
  display: 'block',
};

const LocksContentPopup = ({
  companyId,
  onClose,
}: {
  companyId: number | null;
  onClose: (reload: boolean, userEmail?: string) => void;
}) => {
  const [connectWebviewUrl, setConnectWebviewUrl] = useState(null);
  const [connectWebviewId, setConnectWebviewId] = useState<string | null>(null);
  const [connectedAccountId, setConnectedAccountId] = useState<string | null>(null);
  const [triggerGenerateLink, { data, isSuccess }] =
    usePostAccessCompatibilityOnboardingGenerateLinkMutation();

  const { snackbar } = useSnackbar();

  const { t } = useTranslation();

  const { data: connectedAccount, refetch } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery(
      { legacyCompanyId: companyId, connectWebviewId: connectWebviewId },
      { skip: !companyId || !connectWebviewId }, // Fetch only when both params are available
    );

  const [triggerAddSeamAccount] = usePostAccessCompatibilityAddSeamAccountMutation();
  const [loadingTimeout, setLoadingTimeout] = useState<boolean>(false);

  useEffect(() => {
    triggerGenerateLink({ redirectUrl: '/locks' });
  }, [triggerGenerateLink]);

  useEffect(() => {
    if (isSuccess && data?.connect_view_url) {
      setConnectWebviewUrl(data.connect_view_url);
    }
  }, [isSuccess, data?.connect_view_url]);

  useEffect(() => {
    if (connectedAccount) {
      setConnectedAccountId(connectedAccount.connected_account_id);
    }
  }, [connectedAccount]);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout = null;

    const addSeamAccount = async () => {
      if (connectedAccountId && connectWebviewUrl) {
        setLoadingTimeout(true);

        try {
          const response = await triggerAddSeamAccount({
            importAdditionalSeamAccount: {
              legacyCompanyId: companyId,
              seamConnectedAccountId: connectedAccountId,
            },
          }).unwrap();
          if (response) {
            const { provider_account_user_email } = await refetch().unwrap();
            timerId = setTimeout(() => {
              onClose(true, provider_account_user_email);
              setConnectWebviewUrl(null);
              setLoadingTimeout(false);
            }, 4000);
          } else {
            onClose(false);
          }
        } catch (error) {
          onClose(false);
          if (error.status === 400) {
            let errorMessage = error.data ?? t('error_onboarding_seam_account');
            if (errorMessage.includes('Seam Account already imported')) {
              errorMessage = t('lock_account_already_imported');
            }
            snackbar(errorMessage);
          } else {
            snackbar(t('error_onboarding_seam_account'));
          }
          logger.error('Failed to add additional Seam account', error);
        }
      }
    };

    if (!loadingTimeout) {
      addSeamAccount();
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [
    companyId,
    connectWebviewUrl,
    connectedAccountId,
    isSuccess,
    loadingTimeout,
    onClose,
    refetch,
    triggerAddSeamAccount,
    snackbar,
    t,
  ]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.event?.event_type === 'connect_webview.login_succeeded') {
        setConnectWebviewId(event.data?.event?.connect_webview_id);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [connectedAccount]);

  const isLoading = loadingTimeout;

  if (connectWebviewUrl) {
    return (
      <Box sx={boxCenteredStyle}>
        <Box
          sx={iframeStyle}
          component='iframe'
          allow='clipboard-write'
          src={connectWebviewUrl}
          title='Connect Webview'
          data-testid='connect-webview-iframe-popup'
        />
      </Box>
    );
  }

  return (
    <Box sx={boxCenteredStyle}>
      <LoadingContainer loading={isLoading} />
    </Box>
  );
};

export default LocksContentPopup;
