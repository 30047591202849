// @ts-nocheck - FIXME
import { createSelector } from '@reduxjs/toolkit';
import { endOfDay, isAfter, isBefore, isWithinInterval, startOfDay, subWeeks } from 'date-fns';
import { isSameDayOrAfter, utcStringToDate } from 'helper/date';
import slice from 'lodash/slice';
import take from 'lodash/take';
import { Property } from 'property/propertyType';
import { ApplicationState } from 'redux/reducers/index';
import { IReservation, ReservationState } from 'reservation/reservationType';

const getReservations = (state: ApplicationState) => state.reservations;
const getProperties = (state: ApplicationState) => state.properties;

export const reservationByRoomStatusSelector = (roomStatus: string, limit = 1000) =>
  createSelector([getReservations], (reservations: ReservationState) => {
    const reservationList: IReservation[] =
      Object.keys(reservations.byId).map((key: number) => reservations.byId[key]) || [];
    return take(
      reservationList.filter(
        (reservation: IReservation) => reservation?.room_status === roomStatus,
      ),
      limit,
    );
  });

export const getFilteredReservations = (
  propertyId: number,
  limit = 1,
  accessCodeValidUntil: string,
) =>
  createSelector([getReservations], (reservations: ReservationState) => {
    const reservationList: IReservation[] = Object.keys(reservations.byId).map(
      (key: number) => reservations.byId[key],
    );

    // filter by property id and then sort by latest
    const sorted = reservationList
      .filter(
        (reservation: IReservation) =>
          reservation.property_id === propertyId &&
          isSameDayOrAfter(utcStringToDate(accessCodeValidUntil), new Date()),
      )
      .sort((a: IReservation, b: IReservation) => (a.check_out < b.check_out ? -1 : 1));

    return slice(sorted, 0, limit);
  });

export const getCurrentReservation = (propertyId: number) =>
  createSelector([getReservations, getProperties], (reservations: ReservationState) => {
    const reservationList: IReservation[] = Object.keys(reservations.byId).map(
      (key: number) => reservations.byId[key],
    );
    // filter by property id and then sort by latest
    const sorted = reservationList
      .filter(
        (reservation: IReservation) =>
          reservation.property_id === propertyId &&
          isSameDayOrAfter(utcStringToDate(reservation.check_out), new Date()),
      )
      .sort((a: IReservation, b: IReservation) => (a.check_out < b.check_out ? -1 : 1));
    return sorted?.[0];
  });

export const getWeeklyReservations = (propertyId: number) =>
  createSelector([getReservations], (reservations: ReservationState) => {
    const today = new Date();
    const aWeekAgo = subWeeks(today, 1);

    const reservationList: IReservation[] = Object.values(reservations.byId).filter(
      (reservation: IReservation) => {
        if (reservation.property_id !== parseInt(propertyId)) {
          return false;
        }

        const checkIn = utcStringToDate(reservation.check_in);
        const checkOut = utcStringToDate(reservation.check_out);
        return (
          isWithinInterval(checkIn, {
            start: aWeekAgo,
            end: today,
          }) ||
          isWithinInterval(checkOut, {
            start: aWeekAgo,
            end: today,
          })
        );
      },
    );

    return reservationList;
  });

export const getDailyReservations = (propertyId: number) =>
  createSelector([getReservations], (reservations: ReservationState) => {
    const startOfToday = startOfDay(new Date());
    const endOfToday = endOfDay(startOfToday);

    const reservationList: IReservation[] = Object.values(reservations.byId).filter(
      (reservation: IReservation) => {
        if (reservation.property_id !== parseInt(propertyId)) {
          return false;
        }

        const checkIn = utcStringToDate(reservation.check_in);
        const checkOut = utcStringToDate(reservation.check_out);
        return (
          isWithinInterval(checkIn, {
            start: startOfToday,
            end: endOfToday,
          }) ||
          isWithinInterval(checkOut, {
            start: startOfToday,
            end: endOfToday,
          }) ||
          (isBefore(checkIn, startOfToday) && isAfter(checkOut, endOfToday))
        );
      },
    );
    return reservationList;
  });

export const getReservationById = (reservationId: number) =>
  createSelector([getReservations], (reservations: ReservationState) => {
    return reservations.byId[reservationId];
  });

export const getAllReservations = reservations =>
  createSelector([getProperties], properties => {
    const props = properties?.list
      .map((each: number) => properties.byId[each])
      .filter((each: Property) => each.lock_enabled && each.room_status !== 'requires-activation');
    props.map(property => {
      reservations.map(reservation => {
        if (reservation.property_id === property.id) {
          reservation.property_enabled = property.property_enabled;
        }
      });
    });
    return reservations;
  });

export const getAllReservationsByIds = () => {
  return createSelector([getReservations], (reservations: ReservationState) => {
    const reservationIds = reservations.list;
    return reservationIds.map((id: number) => reservations.byId[id]);
  });
};
