import { GridComparatorFn } from '@mui/x-data-grid-pro';

const priorityMap: { [key: string]: number } = {
  high: 3,
  medium: 2,
  low: 1,
};

export const taskPriorityComparator: GridComparatorFn = (v1, v2) => {
  const priority1 = priorityMap[v1] || 0;
  const priority2 = priorityMap[v2] || 0;

  return priority1 - priority2;
};

const statusMap: { [key: string]: number } = {
  overdue: 4,
  notStarted: 3,
  started: 2,
  completed: 1,
  canceled: 0,
};

export const taskStatusComparator: GridComparatorFn = (v1, v2) => {
  const priority1 = statusMap[v1] || 0;
  const priority2 = statusMap[v2] || 0;

  return priority1 - priority2;
};
