import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { LogDataType, logger } from '@operto/delivery-logs-shared';
import useScheduling from 'hooks/useScheduling';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import { ClearableEmailInput } from '../../ui-library/Components/input/ClearableEmailInput';
import { ClearablePhoneInput } from '../../ui-library/Components/input/ClearablePhoneInput';
import DeliveryLogsSendConfirmDialog from './DeliveryLogsSendConfirmDialog';

interface DeliveryLogsTableActionProps {
  deliveryLogId: string;
  channelType: string;
  communications?: string[];
  deliveryLogsRow: LogDataType;
}

function DeliveryLogsTableAction({ channelType, deliveryLogsRow }: DeliveryLogsTableActionProps) {
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { resendDeliveryLog } = useScheduling();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendDialogRecipient, setSendDialogRecipient] = useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const [isRecipientValid, setIsRecipientValid] = useState(true);

  const isSms = channelType === 'sms';
  const channel = isSms ? t('sms') : t('email').toLowerCase();
  const title = t('send_placeholder_to', { placeholder: channel });

  // Action menu
  const MENU_OPTIONS = {
    VIEW: t('view_details'),
    SEND: t('send_to_guest'),
  };
  type MenuOptionType = (typeof MENU_OPTIONS)[keyof typeof MENU_OPTIONS];

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleDialogClose = () => {
    setSendDialogOpen(false);
    // Move focus back to the button.
    if (menuAnchorEl) {
      menuAnchorEl.focus();
    }
    setMenuAnchorEl(null);
  };

  const handleMoreClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption === MENU_OPTIONS.VIEW) {
      dispatch(toggleSlidebar(SlidebarType.DELIVERY_LOGS_DETAILS, { row: deliveryLogsRow }));
    }

    if (selectedOption === MENU_OPTIONS.SEND) {
      const recipient = deliveryLogsRow.recipient || '';
      setSendDialogRecipient(recipient);
      setSendDialogOpen(true);
    }

    setMenuAnchorEl(null);
  };

  const handleRecipientError = (isError: boolean) => {
    setIsRecipientValid(!isError);
  };

  const handleRecipientChange = (value: string) => {
    setSendDialogRecipient(value);
  };

  const handleSendDialog = async () => {
    setIsSending(true);
    const data = {
      messageId: deliveryLogsRow.id,
      recipient: sendDialogRecipient,
      scheduledAt: deliveryLogsRow.scheduledAt,
      channelType: deliveryLogsRow.channelType,
      reservationId: deliveryLogsRow.reservationId,
      templateId: deliveryLogsRow.templateId,
      guestName: deliveryLogsRow.guestName,
      unitId: deliveryLogsRow.unitId,
      jobId: deliveryLogsRow.jobId,
    };

    try {
      await resendDeliveryLog(data);

      setSendDialogOpen(false);
      setIsSending(false);
      snackbar(t('sent'));
    } catch (error) {
      snackbar(t('error_sending'));
      setIsSending(false);
      logger.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <IconButton size='large' onClick={handleMoreClick} role='button' data-testid='more-button'>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id='delivery-log-action-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMoreClose}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
      >
        <MenuItem onClick={() => handleMoreClose(MENU_OPTIONS.VIEW)}>{MENU_OPTIONS.VIEW}</MenuItem>
        <MenuItem onClick={() => handleMoreClose(MENU_OPTIONS.SEND)}>{MENU_OPTIONS.SEND}</MenuItem>
        <DeliveryLogsSendConfirmDialog
          open={sendDialogOpen}
          onClose={handleDialogClose}
          onSend={handleSendDialog}
          sendDisabled={isSending || !isRecipientValid}
          loading={isSending}
          title={title}
        >
          {isSms ? (
            <ClearablePhoneInput
              phoneValue={sendDialogRecipient}
              onPhoneChange={handleRecipientChange}
              onPhoneError={handleRecipientError}
            />
          ) : (
            <ClearableEmailInput
              emailValue={sendDialogRecipient}
              onEmailChange={handleRecipientChange}
              onEmailError={handleRecipientError}
            />
          )}
        </DeliveryLogsSendConfirmDialog>
      </Menu>
    </>
  );
}

export default DeliveryLogsTableAction;
