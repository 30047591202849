import { differenceInSeconds } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import toDate from 'date-fns-tz/toDate';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import formatRelative from 'date-fns/formatRelative';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import enUS from 'date-fns/locale/en-US';

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm';
export const FORMAT_RELATIVE = {
  lastWeek: "EEEE 'at' p",
  yesterday: "'Yesterday at' p",
  today: "'Today at' p",
  tomorrow: "'Tomorrow at' p",
  nextWeek: "EEEE 'at' p",
  other: "P 'at' p",
};

/**
 * NOTE: date-fns-tz is a little weird with timezone conversion,
 * be careful using utcToZonedTime and zonedTimeToUtc functions from date-fns-tz
 * unless you really understand how it works.
 * https://github.com/marnusw/date-fns-tz/issues/174
 * https://github.com/marnusw/date-fns-tz/issues/118
 *
 * so we need to do this helper function is to convert a date string to a UTC date object.
 * This is useful to avoid confusion on how date-fns-tz works
 *
 * @param date - string date in UTC
 * @returns - date object in UTC
 *
 * @example
 * const utcDate = utcStringToDate('2021-01-01 00:00:00') -> 2021-01-01T00:00:00.000Z
 * once you have the date object, you can use date-fns-tz to convert it to any timezone
 * using formatInTimeZone(utcDate, 'America/New_York', 'yyyy-MM-dd HH:mm:ss')
 */
export const utcStringToDate = (date: string) => {
  return toDate(date, { timeZone: 'UTC' });
};

export const utcToTimeZone = (date: string, timezone: string, format = DATE_FORMAT) => {
  const utcDate = utcStringToDate(date);
  return formatInTimeZone(utcDate, timezone, format);
};

export const timeZoneToUTC = (date: string, timezone: string, format = DATE_FORMAT) => {
  const utcDate = zonedTimeToUtc(date, timezone);
  return formatInTimeZone(utcDate, 'UTC', format);
};

export const isSameDayOrBefore = (dateToCheck: Date, dateToCompare: Date) => {
  return isSameDay(dateToCheck, dateToCompare) || isBefore(dateToCheck, dateToCompare);
};

export const isSameDayOrAfter = (dateToCheck: Date, dateToCompare: Date) => {
  return isSameDay(dateToCheck, dateToCompare) || isAfter(dateToCheck, dateToCompare);
};

export const formatRelativeDate = (date: string, timezone: string) => {
  const dateToCheck = utcToZonedTime(utcStringToDate(date), timezone);
  const relativeDate = utcToZonedTime(new Date(), timezone);
  const secondsDifference = differenceInSeconds(relativeDate, dateToCheck);

  if (secondsDifference < 60 && secondsDifference >= 0) {
    return 'Just now';
  } else if (secondsDifference < 3600) {
    const minutes = Math.floor(secondsDifference / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (secondsDifference < 86400) {
    const hours = Math.floor(secondsDifference / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (secondsDifference < 604800) {
    const days = Math.floor(secondsDifference / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else {
    return '7+ days ago';
  }
};

export const formatRelativeToTimeZone = (date: string, timezone: string) => {
  // NOTE: formatRelative doesnt have timezoned support, so we need to convert it to zoned time first
  const dateToCheck = utcToZonedTime(utcStringToDate(date), timezone);
  const relativeDate = utcToZonedTime(new Date(), timezone);

  return formatRelative(dateToCheck, relativeDate, {
    locale: {
      ...enUS,
      formatRelative: token => FORMAT_RELATIVE[token as keyof typeof FORMAT_RELATIVE],
    },
  });
};

export const dateStringToUTCFormat = (date: string) => {
  const dateInUTCFormat = date.replace(' ', 'T') + 'Z';
  const utcDate = new Date(dateInUTCFormat);
  return utcDate;
};
