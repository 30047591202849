import { SxProps } from '@mui/material';
import { GridSortDirection, GridSortModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Table, TableProps } from 'ui-library/Components/table/Table';

const FETCH_LIMIT = 100;

export type PaginatedTableHandle = {
  searchValue?: string;
  handleSearch: (value?: string) => void;
};

export type PaginatedTableProps = TableProps & {
  title?: string | React.ReactNode;
  enableToolbar?: boolean;
  onExport?: () => void;
  onAdd?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onFetch?: (serverPageNum: number, limit: number, searchString?: string) => void;
  searchToolbarSx?: SxProps;
  renderBody?: () => React.ReactNode;
  addTooltipValue?: string;
  initialPageSize?: number;
  disableAdd?: boolean;
  additionalComponents?: React.ReactNode;
  initialState?: GridValidRowModel;
};

export const PaginatedTable = React.forwardRef<PaginatedTableHandle, PaginatedTableProps>(
  function PaginatedTable(
    {
      rows,
      rowCount,
      loading,
      title,
      enableToolbar,
      onFetch,
      onAdd,
      onExport,
      disableAdd,
      searchToolbarSx,
      additionalComponents,
      addTooltipValue,
      initialState,
      initialPageSize = 25,
      ...props
    },
    ref,
  ) {
    const serverPageNumRef = useRef(0);
    const [tablePage, setTablePage] = useState(0);
    const [tablePageSize, setTablePageSize] = useState(initialPageSize);
    const [searchValue, setSearchValue] = useState('');

    const handlePageSizeChange = (size: number) => {
      setTablePage(0);
      setTablePageSize(size);
    };

    const handlePageChange = (page: number) => {
      // NOTE: workaround for bug that triggers 'onPageChange' when page size change.
      // this bug results to fetching more than needed.
      if (page - tablePage > 1 || loading) {
        return;
      }

      setTablePage(page);
      const numOfRowsToDisplay = tablePageSize * (page + 1);
      if (numOfRowsToDisplay > rows.length && rows.length < rowCount) {
        onFetch?.(serverPageNumRef.current++, FETCH_LIMIT, searchValue);
      }
    };

    const handleSearch = useCallback(
      (value?: string) => {
        serverPageNumRef.current = 0;
        setTablePage(0);
        setSearchValue(value);
        onFetch?.(serverPageNumRef.current++, FETCH_LIMIT, value);
      },
      [onFetch],
    );

    useImperativeHandle(
      ref,
      () => ({
        searchValue,
        handleSearch,
      }),
      [handleSearch, searchValue],
    );

    useEffect(() => {
      handleSearch();
    }, [handleSearch]);

    return (
      <Table
        pagination
        hideFooterSelectedRowCount
        loading={loading}
        rows={rows}
        rowCount={rowCount}
        page={tablePage}
        pageSize={tablePageSize}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        searchToolbarProps={
          enableToolbar && {
            onChange: handleSearch,
            onAdd,
            disableAdd,
            onExport,
            title,
            value: searchValue ?? '',
            containerSx: searchToolbarSx,
            addTooltipValue,
            additionalComponents: additionalComponents,
          }
        }
        initialState={initialState}
        {...props}
      />
    );
  },
);

export const getSortModelByUrl = (searchParams: URLSearchParams): GridSortModel => {
  const sortBy = searchParams.get('sort_by');

  if (sortBy) {
    const model = sortBy.split('(');

    return [
      {
        field: model[0],
        sort: model[1].replace(')', '') as GridSortDirection,
      },
    ];
  }

  return [];
};

export const sortTableByUrl = (sortModel: GridSortModel, searchParams: URLSearchParams) => {
  const urlSearchParams = new URLSearchParams(searchParams);

  if (sortModel.length > 0) {
    urlSearchParams.set('sort_by', `${sortModel[0]?.field}(${sortModel[0]?.sort})`);
  } else {
    urlSearchParams.delete('sort_by');
  }

  return urlSearchParams;
};
