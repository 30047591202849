import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Text } from '@operto/ui';
import { getTeamsTasks } from 'api/messagingAPI';
import { getReservationById } from 'api/reservationAPI';
import { Loading } from 'Common/Loading';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfirmDialogProps } from '../../../Common/Dialog/ConfirmDialog';

type TeamsCreatedTasksDialogProps = Pick<ConfirmDialogProps, 'open' | 'onClose'>;

export const TeamsCreatedTasksDialog = ({ open, onClose }: TeamsCreatedTasksDialogProps) => {
  const { pathname } = useLocation();
  const [tasks, setTasks] = useState([]);
  const [fetchingTasks, setFetchingTasks] = useState(false);

  const renderTasks = () => {
    if (tasks.length === 0) {
      return <Text.BodyLarge>No tasks found.</Text.BodyLarge>;
    }

    return (
      <List dense sx={{ maxHeight: 500 }}>
        {tasks.map(task => {
          return (
            <ListItem key={task.Id} disablePadding>
              <ListItemText primary={task.Name} secondary={'ID: ' + task.Id} />
              <Box
                sx={{
                  backgroundColor: task.Status == 'Completed' ? 'green' : 'grey',
                  borderRadius: '4px',
                  padding: '2px 4px',
                  color: 'white',
                }}
              >
                <Typography sx={{ fontSize: '12px', padding: '2px 4px' }}>{task.Status}</Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setFetchingTasks(true);
        const reservationId = pathname.split('-').pop();
        if (!reservationId) {
          throw new Error('Reservation ID not found');
        }

        const reservation = await getReservationById(reservationId);
        const reservationExternalId = reservation?.data?.data?.external_id;
        if (!reservationExternalId) {
          throw new Error('Reservation external ID not found');
        }

        const response = await getTeamsTasks(reservationExternalId);
        const data = response.data?.data;
        if ('error' in data) {
          throw new Error(data.error);
        }

        setTasks(data);
      } catch (error) {
        logger.error('Fetching services error: ', error);
      } finally {
        setFetchingTasks(false);
      }
    };

    if (open) {
      fetchTasks();
    }
  }, [open, pathname]);

  return (
    <Dialog maxWidth='md' open={open} fullWidth>
      <DialogTitle>{'Created Tasks'}</DialogTitle>
      <DialogContent>{fetchingTasks ? <Loading /> : renderTasks()}</DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          data-testid='modal-confirm-btn'
          autoFocus
          variant='contained'
          onClick={onClose}
          disabled={fetchingTasks}
        >
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
