import { SellOutlined } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { currencies } from '@operto/payments-shared';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { enabledIfVariablesInitialized } from 'helper/reactHookEnabledHelper';
import { useGetCompanyContext, useGetCurrencies, useUpdateCompanyContext } from 'hooks/usePayments';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { default as React, useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import AddCard from 'ui-library/Components/card/AdderCard';
import { ActionButton, FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';

type CurrencyForm = {
  code: string;
};

export interface UpsellSettingsProps {
  companyId: number;
}

const UpsellSettings = ({ companyId }: UpsellSettingsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(userSelector());
  const { data: companyContext, refetch } = useGetCompanyContext(
    {
      params: { companyId: companyId, withSession: true },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    enabledIfVariablesInitialized(accessToken, companyId),
  );
  const { data: currencyOptions } = useGetCurrencies(
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    enabledIfVariablesInitialized(accessToken),
  );
  const { mutateAsync: updateCompanyContext } = useUpdateCompanyContext({
    params: { companyId: companyId },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { handleSubmit, control, reset, getValues } = useForm<CurrencyForm>({
    mode: 'all',
    defaultValues: {
      code: '',
    },
  });

  const { isDirty, isValid } = useFormState({
    control,
  });

  const isFormValidAndDirty = isDirty && isValid;

  const onFormValid = async () => {
    let message = {
      message: t('upsell_setting_update_success'),
      variant: SnackbarVariant.SUCCESS,
    };

    try {
      await updateCompanyContext({ currency: getValues('code') });
      refetch();
    } catch (error) {
      logger.error(error);
      message = {
        message: t('upsell_setting_update_fail'),
        variant: SnackbarVariant.ERROR,
      };
    }

    dispatch(toggleSnackbar(SnackbarTypes.OPEN, message));
  };

  useEffect(() => {
    if (companyContext?.data?.currency) {
      reset({ code: companyContext.data.currency });
    }
  }, [companyContext?.data?.currency, reset]);

  return (
    <AddCard
      title={t('offers')}
      footerChildren={
        <ActionButton
          disabled={!isFormValidAndDirty}
          testId='company-save-button'
          variant='contained'
          color='primary'
          onClick={handleSubmit(onFormValid)}
        >
          {t('save')}
        </ActionButton>
      }
    >
      <Box sx={{ marginBottom: '16px' }}>
        <FormHeader align='left'>{t('currency')}</FormHeader>
      </Box>
      <Typography variant='body2'>{t('currency_settings_body')}</Typography>

      <FormControl fullWidth sx={{ margin: '0px' }}>
        <Controller
          name='code'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              labelId='code-label'
              fullWidth
              style={{ marginTop: 16, marginBottom: 16 }}
              inputProps={{ style: { textAlign: 'start' } }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              variant='outlined'
              value={getValues('code')}
            >
              {(currencyOptions?.data as typeof currencies)?.map(c => (
                <MenuItem key={c.code} value={c.code}>
                  {c.code}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </AddCard>
  );
};

const SettingsContainer = () => {
  const company = useAppSelector(companySelector());
  const { t } = useTranslation();
  const navigate = useNavigate();

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('settings'),
      href: '/settings/account',
    },
    {
      name: t('offers'),
    },
  ];

  return (
    <>
      <TitlebarRoot>
        <TitlebarTitleIcon>
          <SellOutlined />
        </TitlebarTitleIcon>
        <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />
      </TitlebarRoot>
      <Box maxWidth='838px' marginX='auto' marginY='10px' paddingTop='10px'>
        <UpsellSettings companyId={company.id} />
      </Box>
    </>
  );
};

export default SettingsContainer;
