import { Skeleton, Stack, SxProps } from '@mui/material';
import React from 'react';

export default function CopilotPanelSkeleton() {
  return (
    <Stack sx={skeletonContainerStyles}>
      <Skeleton variant='rectangular' width='100%' height={12} sx={skeletonStyles} />
      <Skeleton variant='rectangular' width='90%' height={12} sx={skeletonStyles} />
      <Skeleton variant='rectangular' width='80%' height={12} sx={skeletonStyles} />
    </Stack>
  );
}

const skeletonContainerStyles: SxProps = {
  flexDirection: 'column',
  gap: 2,
  width: '100%',
  padding: '16px',
};

const skeletonStyles: SxProps = {
  background: 'linear-gradient(to right, #a7bfff, #e5b5e5)',
  borderRadius: '4px',
};
