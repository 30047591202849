import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, SxProps, Theme, Toolbar } from '@mui/material';
import { Text } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React, { ReactNode, useState } from 'react';
import ConfirmLeaveDialog from './Dialog/ConfirmLeaveDialog';
import ActionSnackbar from './Snackbar/ActionSnackbar';

type FormContainerHeaderProps = {
  title: string;
  submitButtonTitle?: string;
  submitButtonIcon?: ReactNode;
  submitButtonEnabled?: boolean;
  submitButtonSx?: SxProps<Theme>;
  loading?: boolean;
  dirty?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  useConfirmLeaveDialogPopup?: boolean;
};

export const FormContainerHeader = ({
  title,
  submitButtonTitle = 'Save',
  submitButtonIcon = <SaveIcon />,
  submitButtonEnabled = false,
  loading = false,
  dirty = false,
  onClose,
  onSubmit,
  submitButtonSx,
  useConfirmLeaveDialogPopup = false,
}: FormContainerProps) => {
  const [confirmClose, setConfirmClose] = useState(false);
  const [confirmLeaveDialogOpen, setConfirmLeaveDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setConfirmLeaveDialogOpen(dirty);
    setConfirmClose(dirty);
    if (!dirty) {
      onClose?.();
    }
  };

  return (
    <>
      <Toolbar sx={toolbarStyles}>
        <IconButton size='large' edge='start' aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Text variant='h3-700' style={titleStyles}>
          {title}
        </Text>
        {onSubmit && (
          <LoadingButton
            disabled={!dirty && !submitButtonEnabled}
            loading={loading}
            onClick={onSubmit}
            variant='contained'
            color='primary'
            data-testid='form-container-submit-button'
            startIcon={submitButtonIcon === undefined ? <SaveIcon /> : submitButtonIcon}
            sx={submitButtonSx}
          >
            {submitButtonTitle}
          </LoadingButton>
        )}
      </Toolbar>

      {useConfirmLeaveDialogPopup ? (
        <ConfirmLeaveDialog
          open={confirmLeaveDialogOpen}
          onClose={() => {
            setConfirmLeaveDialogOpen(false);
          }}
          onSubmit={onClose}
          submitButtonText={t('discard_changes')}
          cancelButtonText={t('continue_editing')}
          description={t('youll_lose_any_unsaved_changes')}
        />
      ) : (
        <ActionSnackbar
          onClose={onClose}
          open={confirmClose}
          setOpen={setConfirmClose}
          severity='error'
          actionButtonText={t('yes_capitalized')}
          snackbarMessage={t(
            'you_have_unsaved_changes_that_will_be_lost_if_you_exit_are_you_sure_you_want_to_exit',
          )}
        />
      )}
    </>
  );
};

type FormContainerProps = FormContainerHeaderProps & {
  children?: ReactNode;
  renderHeader?: () => ReactNode; // content header
  renderFooter?: () => ReactNode;
};

export const FormContainer = ({
  children,
  renderHeader,
  renderFooter,
  ...rest
}: FormContainerProps) => {
  return (
    <>
      <FormContainerHeader {...rest} />
      {renderHeader?.()}
      <Box
        flex={1}
        p={3}
        overflow={'scroll'}
        bgcolor='background.default01'
        data-testid='form-container-content'
      >
        {children}
      </Box>
      {renderFooter?.()}
    </>
  );
};

const toolbarStyles: SxProps<Theme> = {
  position: 'sticky',
  top: '0px',
  zIndex: 2, // just enough to be on top of the content
  backgroundColor: 'background.paper',
};

const titleStyles: SxProps<Theme> = {
  mx: 1.5,
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};
