import { IMember } from 'member/memberType';
import { FilterDataType, FilterGroup } from 'types/filter-type';

export const createTasksOptions = (members: IMember[]) => {
  const filterGroups: FilterGroup[] = [];

  const taskStatusOptions: FilterDataType[] = [
    { filterType: 'checkbox', label: 'Overdue', name: 'overdue' },
    { filterType: 'checkbox', label: 'Not Started', name: 'not_started' },
    { filterType: 'checkbox', label: 'Started', name: 'started' },
    { filterType: 'checkbox', label: 'Completed', name: 'completed' },
    { filterType: 'checkbox', label: 'Canceled', name: 'canceled' },
  ];

  filterGroups.push({
    label: 'Task Status',
    groupName: 'task_status',
    options: taskStatusOptions,
    order: 1,
  });

  const taskPriorityOptions: FilterDataType[] = [
    { filterType: 'checkbox', label: 'High', name: 'high' },
    { filterType: 'checkbox', label: 'Medium', name: 'medium' },
    { filterType: 'checkbox', label: 'Low', name: 'low' },
    { filterType: 'checkbox', label: 'None', name: 'none' },
  ];

  filterGroups.push({
    label: 'Priority',
    groupName: 'task_priority',
    options: taskPriorityOptions,
    order: 2,
  });

  const taskAssigneeOptions: FilterDataType[] = members.map(member => ({
    filterType: 'checkbox',
    label: member.name,
    name: member.id.toString(),
  }));

  filterGroups.push({
    label: 'Assignee',
    groupName: 'task_assignee',
    options: taskAssigneeOptions,
    order: 3,
  });

  const dueDateOptions: FilterDataType[] = [
    {
      filterType: 'datepicker',
      label: '',
      name: 'due_date',
      singleSelect: false,
    },
  ];
  filterGroups.push({
    label: 'Due date',
    groupName: 'due_date',
    options: dueDateOptions,
    order: 4,
  });

  const requestDateOptions: FilterDataType[] = [
    {
      filterType: 'datepicker',
      label: '',
      name: 'request_date',
      singleSelect: false,
    },
  ];
  filterGroups.push({
    label: 'Request date',
    groupName: 'request_date',
    options: requestDateOptions,
    order: 5,
  });

  return filterGroups.sort((a, b) => (a.order || 0) - (b.order || 0));
};
