import { Box, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Text } from '@operto/ui';
import React, { ReactNode } from 'react';

const isStringOrNumber = (str: unknown) => {
  return typeof str === 'string' || typeof str === 'number';
};

type TableCellProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  testId?: string;
  flexDirection?: 'column' | 'row';
  sx?: SxProps;
  hasTooltip?: boolean;
};

export const TableCellWithTooltip = ({
  tooltipText,
  ...rest
}: { tooltipText: string | ReactNode } & TableCellProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Box sx={{ width: '100%' }}>
        <TableCell {...rest} />
      </Box>
    </Tooltip>
  );
};

export const TableCell = ({
  title,
  description,
  testId,
  flexDirection,
  sx = {},
}: TableCellProps) => {
  return (
    <Stack data-testid={testId} flexDirection={flexDirection} sx={{ width: '100%', ...sx }}>
      {isStringOrNumber(title) ? (
        <Typography sx={{ overflow: 'inherit', textOverflow: 'inherit' }}>
          <Text.BodySmall>{title}</Text.BodySmall>
        </Typography>
      ) : (
        title
      )}
      {isStringOrNumber(description) ? (
        <Typography sx={{ overflow: 'inherit', textOverflow: 'inherit' }}>
          <Text.CaptionLarge color='text.secondary'>{description}</Text.CaptionLarge>
        </Typography>
      ) : (
        description
      )}
    </Stack>
  );
};
