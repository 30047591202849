import { Stack, SxProps, Typography } from '@mui/material';
import CopilotIcon60 from 'assets/img/icons/icn-copilot60.svg';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export default function CopilotPanelEmpty() {
  const { t } = useTranslation();

  return (
    <Stack sx={bodyContainerStyles}>
      <Stack sx={emptyContainerStyles}>
        <CopilotIcon60 />

        <Typography variant='h2-700' sx={emptyTitleStyles}>
          {t('instant_reply')}
        </Typography>

        <Typography variant='h4-300' sx={emptyMessageStyles}>
          {t('instant_reply_empty_message')}
        </Typography>
      </Stack>
    </Stack>
  );
}

const emptyTitleStyles: SxProps = {
  background: 'linear-gradient(98deg, #155EFF 8.22%, #C66DAB 72.43%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textTransform: 'capitalize',
};

const emptyContainerStyles: SxProps = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  width: '300px',
};

const emptyMessageStyles: SxProps = {
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.6)',
};

const bodyContainerStyles: SxProps = {
  padding: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  overflowY: 'auto',
  flex: 1,
  width: '100%',
};
