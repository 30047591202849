import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Chip, Popover, Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { parse, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useRef, useState } from 'react';
import { FilterDataType, FilterGroup } from 'types/filter-type';
import { DatePickerOption } from './DatePickerOption';
import {
  chipArrowStyle,
  clearButtonStyle,
  dateButtonWrapperStyle,
  filterChipStyle,
  popoverStyle,
  todayButtonStyle,
} from './FilterPanel';
import { FiltersToolbarProps } from './FiltersToolbar';

export type DatePickerFilterPanelProps = Omit<
  FiltersToolbarProps,
  'filterGroups' | 'onFilterClearAll'
> & {
  filter: FilterGroup;
};

export const DatePickerFilterPanel = ({
  filter,
  searchParams,
  onFilterChange,
  onFilterClear,
  isClearableFilters,
  updateChipLabel,
}: DatePickerFilterPanelProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const chipRef = useRef();

  const handleChange = (value: DateRange<Date>) => {
    const fromKey = `${filter.groupName}_from`;
    const untilKey = `${filter.groupName}_until`;

    const fromValue = value[0];
    let untilValue = value[0];
    if (value[1] != null) {
      untilValue = value[1];
    }

    searchParams.set(fromKey, format(fromValue, 'yyyy-MM-dd'));
    searchParams.set(untilKey, format(untilValue, 'yyyy-MM-dd'));

    onFilterChange(searchParams);
  };

  return (
    <>
      <Chip
        ref={chipRef}
        key={filter.groupName}
        label={
          updateChipLabel(filter.groupName) !== ''
            ? updateChipLabel(filter.groupName)
            : filter.label
        }
        variant='outlined'
        sx={filterChipStyle(updateChipLabel(filter.groupName) !== '')}
        onClick={() => setIsFilterOpen(true)}
        onDelete={() => setIsFilterOpen(true)}
        deleteIcon={
          <ArrowDropDownIcon sx={chipArrowStyle(updateChipLabel(filter.groupName) !== '')} />
        }
        data-testid={`${filter.groupName}-filter-button`}
      />
      <Popover
        id={`popover-${filter.groupName}`}
        open={isFilterOpen}
        anchorEl={chipRef.current}
        onClose={() => setIsFilterOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverStyle(true)}
        data-testid={`${filter.groupName}-popover`}
      >
        {filter.options.map((subOption: FilterDataType, index: number) => {
          return (
            <DatePickerOption
              key={index}
              onChange={handleChange}
              defaultValue={
                searchParams.get(`${filter.groupName}_from`) != null
                  ? [
                      parse(
                        searchParams.get(`${filter.groupName}_from`),
                        'yyyy-MM-dd',
                        startOfDay(new Date()),
                      ),
                      parse(
                        searchParams.get(`${filter.groupName}_until`),
                        'yyyy-MM-dd',
                        startOfDay(new Date()),
                      ),
                    ]
                  : [startOfDay(new Date()), startOfDay(new Date())]
              }
            />
          );
        })}
        <Stack direction='row' sx={dateButtonWrapperStyle}>
          <Button
            variant='outlined'
            onClick={() => {
              searchParams.set(`${filter.groupName}_from`, format(new Date(), 'yyyy-MM-dd'));
              searchParams.set(`${filter.groupName}_until`, format(new Date(), 'yyyy-MM-dd'));
              onFilterChange(searchParams);
            }}
            sx={todayButtonStyle}
          >
            Today
          </Button>

          <Button
            variant='text'
            onClick={() => onFilterClear(filter.groupName)}
            sx={clearButtonStyle(isClearableFilters(filter.groupName))}
          >
            Clear
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
