import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useMedia } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import * as React from 'react';

export interface LocksAssignPropertyDropDownSearchProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  handleClearInput: (value: string) => void;
  placeholder?: string;
  toggleGroupFilter?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  showTuneIcon?: boolean;
}

const LocksAssignPropertyDropDownSearch = ({
  value,
  onChange,
  size = 'medium',
  fullWidth = false,
  handleClearInput,
  placeholder,
  toggleGroupFilter,
  showTuneIcon = true,
}: LocksAssignPropertyDropDownSearchProps) => {
  const { isMobile } = useMedia();
  const { t } = useTranslation();

  return (
    <FormControl
      variant='outlined'
      fullWidth={fullWidth}
      size={size}
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        margin: isMobile ? '0 8px 0 0' : undefined,
      }}
      data-testid='search-field'
    >
      <OutlinedInput
        inputProps={{ 'data-testid': 'search-field-input' }}
        value={value}
        onChange={onChange}
        onClick={e => e.stopPropagation()}
        placeholder={placeholder ? placeholder : t('default_search_text')}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position='end'>
            {value ? (
              <ClearIcon
                onClick={() => handleClearInput('')}
                sx={{ cursor: 'pointer' }}
                data-testid='search-field-clear-icon'
              />
            ) : (
              showTuneIcon && (
                <TuneIcon
                  data-testid='search-field-tune-icon'
                  onClick={toggleGroupFilter}
                  sx={{
                    cursor: toggleGroupFilter ? 'pointer' : 'default',
                    marginRight: isMobile ? '0' : '4px',
                  }}
                />
              )
            )}
          </InputAdornment>
        }
        sx={{
          borderRadius: '25px',
          '& .MuiOutlinedInput-input': {
            padding: isMobile ? '12px 14px' : '16px 14px',
          },
          marginRight: '20px',
        }}
      />
    </FormControl>
  );
};

export default LocksAssignPropertyDropDownSearch;
