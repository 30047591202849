import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import { Box, IconButton, SxProps, Stack, Tooltip, tooltipClasses } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { theme, useMedia } from '@operto/ui';
import CopilotInputIconDisabled from 'assets/img/icons/icn-copilot-input-disabled.svg';
import CopilotInputIcon from 'assets/img/icons/icn-copilot-input.svg';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import TasksTable from 'Pages/Tasks/TasksTable';
import React, { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Channel as TChannel } from 'stream-chat';
import {
  Channel,
  MessageInput,
  MessageList,
  MessageToSend,
  Thread,
  useChannelActionContext,
  Window,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { userSelector } from 'user/state/userSelectors';
import CustomChannelHeader from './CustomChannelHeader';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import { MessageFilterIndex } from './MessagingPage';

interface IChatMessagesProps {
  currentChannel: TChannel<DefaultStreamChatGenerics>;
  setCurrentChannel: (channel: TChannel<DefaultStreamChatGenerics>) => void;
  handleNewChat: (onNewChat: boolean) => void;
  handleChannelOnClick: (channel: TChannel) => void;
  isNewChat: boolean;
  filterIndex: MessageFilterIndex;
  isMessengerEnabled: boolean;
  onCopilotButtonClick: () => void;
  onSendMessage?: (message: MessageToSend) => void;
  copilotButtonDisabled?: boolean;
  onTaskClick: (row: GridRowParams<Task>) => void;
  onNewTaskClick: () => void;
}

interface CustomMessageInputProps {
  isMessengerEnabled: boolean;
  onCopilotButtonClick: () => void;
  onSendMessage?: (message: MessageToSend) => void;
  copilotButtonDisabled?: boolean;
}

const CustomMessageInput = forwardRef<HTMLTextAreaElement, CustomMessageInputProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useAppFeatures();
    const featureCopilotEnabled = isFeatureEnabled('copilot') && !props.copilotButtonDisabled;

    const messengerSetting = useAppSelector(messengerSettingSelector());
    const { sendMessage } = useChannelActionContext();

    const isCopilotEnabled = messengerSetting.mp_instant_reply_enabled;

    const overrideSubmitHandler = (message: MessageToSend) => {
      sendMessage(message, { target_channel_type: 'sms' }); // or 'whatsapp' in the future
      props.onSendMessage && props.onSendMessage(message);
    };

    const handleCopilotClick = () => {
      if (isCopilotEnabled) {
        props.onCopilotButtonClick();
      }
    };

    return props.isMessengerEnabled ? (
      <Stack sx={inputContainerStyles}>
        {featureCopilotEnabled && (
          <Tooltip
            title={isCopilotEnabled ? '' : t('instant_reply_disabled_message')}
            slotProps={tooltipSlotProps}
            placement='bottom'
          >
            <IconButton sx={{ padding: '0px' }} onClick={handleCopilotClick}>
              <Box
                style={{
                  ...copilotIconStyles,
                  ...(isCopilotEnabled ? copilotIconEnabledStyles : copilotIconDisabledStyles),
                }}
              >
                {isCopilotEnabled ? <CopilotInputIcon /> : <CopilotInputIconDisabled />}
              </Box>
            </IconButton>
          </Tooltip>
        )}

        <MessageInput
          grow
          maxRows={15}
          overrideSubmitHandler={overrideSubmitHandler}
          additionalTextareaProps={{
            // @ts-expect-error the property is not in the type definition
            ref: ref,
            style: {
              ...theme.typography['body-lg-400'],
              color: theme.palette.text.primary,
              opacity: '0.75',
            },
          }}
        />
      </Stack>
    ) : null;
  },
);

CustomMessageInput.displayName = 'CustomMessageInput';

const ChatMessages = forwardRef<HTMLTextAreaElement, IChatMessagesProps>(
  (
    {
      currentChannel,
      setCurrentChannel,
      handleNewChat,
      handleChannelOnClick,
      isNewChat,
      filterIndex,
      isMessengerEnabled,
      onCopilotButtonClick,
      onSendMessage,
      copilotButtonDisabled,
      onTaskClick,
      onNewTaskClick,
    },
    ref,
  ) => {
    const { isFeatureEnabled } = useAppFeatures();
    const { isDesktop, isTablet, isMobile } = useMedia();
    const displayChat = (isDesktop || isTablet || isMobile) && currentChannel;
    const [showChevronTabletIcon, setShowChevronTabletIcon] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const loggedInMember = useAppSelector(userSelector());
    const company = useAppSelector(companySelector());
    useEffect(() => {
      if (currentChannel) {
        isTablet && setShowChevronTabletIcon(true);
      }
    }, [currentChannel, isTablet]);

    const channelId = currentChannel?.data.id as string;
    const determineTabAndReservationId = (channelId: string) => {
      if (!channelId) {
        return { isMembersTab: false, resID: null };
      }
      // Check if the string contains "members"
      const isMembersTab = channelId.includes('members');
      let resID = null;

      // If in guest tab
      if (!isMembersTab) {
        // Extract reservationId for Guests tab
        const parts = channelId.split('-');
        resID = parts[1]; // The second part is the reservationId
      }

      return { isMembersTab, resID };
    };
    const { isMembersTab, resID } = determineTabAndReservationId(channelId);

    const handleChevronClick = () => {
      setCurrentChannel(undefined);
    };

    const renderChevronIcon = () => {
      const handleChevronTabletClick = () => {
        setShowChevronTabletIcon(false);
        handleChevronClick();
      };

      return (
        <>
          {!isFeatureEnabled('hackfest', company.id) && showChevronTabletIcon && (
            <IconButton
              sx={{
                width: '48px',
                height: '48px',
              }}
            >
              <ChevronRightOutlined
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 32,
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                  background: 'white',
                }}
                onClick={handleChevronTabletClick}
              />
            </IconButton>
          )}
        </>
      );
    };

    const handleTabClick = (displayTaskTab: number) => {
      setActiveTab(displayTaskTab);
    };

    return (
      <Box
        id='chat-messages'
        sx={{
          display: 'flex',
          flex: '1 0 0',
        }}
      >
        {!isFeatureEnabled('hackfest', company.id) && showChevronTabletIcon && (
          <Box sx={{ position: 'absolute', top: 13, left: -25, zIndex: 'tooltip' }}>
            {renderChevronIcon()}
          </Box>
        )}

        {isNewChat ? (
          <MessagePeopleListChatContainer
            onNewChat={handleNewChat}
            peopleType={filterIndex}
            user={loggedInMember}
            lastClickedOnChannel={handleChannelOnClick}
          />
        ) : (
          <Box sx={{ display: displayChat ? 'flex' : 'none', flex: '1 0 0' }}>
            <Channel>
              <Window>
                <CustomChannelHeader
                  onChevronClick={handleChevronClick}
                  showFavouriteIcon={filterIndex === MessageFilterIndex.GUESTS}
                  showUnitName={filterIndex === MessageFilterIndex.GUESTS}
                  isGuestTab={filterIndex === MessageFilterIndex.GUESTS}
                  onTaskTabClick={handleTabClick}
                  activeTab={activeTab}
                  resID={resID}
                  propertyName={currentChannel?.data.property_name as string}
                  onNewTaskClick={onNewTaskClick}
                />

                {isMembersTab ? (
                  <MessageList />
                ) : activeTab === 1 ? (
                  <Box sx={containerStyles}>
                    <TasksTable type='messenger' resID={resID} onTaskClick={onTaskClick} />
                  </Box>
                ) : (
                  <MessageList />
                )}

                {activeTab !== 1 && (
                  <CustomMessageInput
                    isMessengerEnabled={isMessengerEnabled}
                    onCopilotButtonClick={onCopilotButtonClick}
                    ref={ref}
                    onSendMessage={onSendMessage}
                    copilotButtonDisabled={copilotButtonDisabled}
                  />
                )}
              </Window>
              <Thread />
            </Channel>
          </Box>
        )}
      </Box>
    );
  },
);

ChatMessages.displayName = 'ChatMessages';

const containerStyles: SxProps = {
  margin: '24px 24px',
  borderRadius: '16px',
  boxShadow: `
  0px 1px 3px 0px #CACDDA80,
  0px 0px 1px 0px #BFC2D14D,
  0px 1px 1px 0px #9098B71A
`,
  backgroundColor: '#FFFFFF',
  overflow: 'auto',
  justifyContent: 'flex-start',
  display: 'inline-block',
};

export default ChatMessages;

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '0px',
      },
    },
  },
};

const inputContainerStyles: SxProps = {
  flexDirection: 'row',
  padding: '0px 16px 16px 16px',
  alignItems: 'flex-end',
  justifyContent: 'center',
  '& div[class^="str-chat__message-input"]': {
    padding: '0px',
  },
};

const copilotIconStyles: CSSProperties = {
  height: '48px',
  width: '48px',
  borderRadius: '50px',
  padding: '8px',
};

const copilotIconEnabledStyles: CSSProperties = {
  background: 'linear-gradient(146deg, #155EFF -12.23%, #E57CC6 118.35%)',
};

const copilotIconDisabledStyles: CSSProperties = {
  background: 'rgba(0, 0, 0, 0.12)',
};
