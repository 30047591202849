import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { useMedia } from '@operto/ui';
import CopilotPanel, { copilotPanelRefType } from 'Common/CopilotPanel/CopilotPanel';
import SwipeableDrawer from 'Common/Drawer/SwipeableDrawer';
import FabIcon from 'Common/Icons/FabIcon';
import { OpertoLogger } from 'Logger/logger';
import { createMemberChannel, notifyGuestAction } from 'Pages/Messaging/MessagingActions';
import SettingsTabPage from 'Pages/Messaging/Settings/index';
import TasksDetails from 'Pages/Tasks/TasksDetails';
import { getMessengerSetting } from 'company/state/companyAction';
import { messengerSettingSelector } from 'company/state/companySelectors';
import { ClientContext } from 'helper/streamChatHelper';
import { useAppFeatures } from 'lib/app-features';
import { cloneDeep } from 'lodash';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchReservation } from 'redux/copilot-chat/api-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ChannelFilters, ChannelSort, DefaultGenerics, Channel as TChannel } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { userSelector, userStateSelector } from 'user/state/userSelectors';
import ChannelMessagesList from './ChannelMessagesList';
import ChatMessages from './ChatMessages';
import CustomSearchAndFilter from './CustomSearchAndFilter';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import './MessageStyles.css';
import { MIN_MESSAGES_DATE } from './MessagingConstants';

export enum MessageFilterType {
  GUESTS = 'Guests',
  MEMBERS = 'Members',
}

export enum MessageFilterIndex {
  GUESTS = 0,
  MEMBERS = 1,
  SETTINGS = 2,
}

const labelName = {
  GUESTS: 'Guest',
  MEMBERS: 'Members',
  SETTINGS: 'Settings',
};

export const MessagingPage = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const location = useLocation();
  const [isCopilotPanelOpen, setIsCopilotPanelOpen] = useState(true);
  const channelListeners = useRef<{ unsubscribe: () => void }[]>([]);
  const isMemberSection = location.pathname.includes('members');
  const [isNewChat, setIsNewChat] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<TChannel<DefaultGenerics>>(undefined);
  const [filterIndex, setFilterIndex] = useState(
    isMemberSection ? MessageFilterIndex.MEMBERS : MessageFilterIndex.GUESTS,
  );

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const copilotPanelRef = useRef<copilotPanelRefType | null>(null);

  const companyId = channelId?.split('-')?.[0];
  const reservationId = channelId?.split('-')?.[1];

  const { isDesktop, isTablet, isMobile } = useMedia();

  const featuresSelector = useAppSelector(userStateSelector());

  const [previousChannelId, setPreviousChannelId] = useState(undefined);

  const [searchValue, setSearchValue] = useState(undefined);

  const [selectedChips, setSelectedChips] = useState([]);

  const [showFullSearchBar, setShowFullSearchBar] = useState(!isMobile);

  const [showMessagesTitle, setShowMessagesTitle] = useState(true);

  const [taskId, setTaskId] = useState<string>();

  const navigate = useNavigate();

  const [showAddTaskPanel, setShowAddTaskPanel] = useState(false);

  const { isFeatureEnabled } = useAppFeatures();
  const featureCopilotEnabled = isFeatureEnabled('copilot');
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;
  const isCopilotEnabled = messengerSetting.mp_instant_reply_enabled && featureCopilotEnabled;
  const isGuestTab = filterIndex === MessageFilterIndex.GUESTS;
  const showFloatingFab = !isDesktop && !currentChannel;

  const isMobileChatOpened = isMobile && currentChannel;

  const isMessengerEnabled =
    (filterIndex === MessageFilterIndex.GUESTS && guestMessengerEnabled) ||
    (filterIndex === MessageFilterIndex.MEMBERS && memberMessengerEnabled);

  const handleTaskUpdate = (task: Task) => {
    setTaskId(task.id);
  };

  const onSelectedChipsChange = (chips: string[]) => {
    setSelectedChips(chips);
  };

  const handleNewChat = (onNewChat: boolean) => {
    setIsNewChat(onNewChat);
  };

  const setTabIndex = (value: number) => {
    setFilterIndex(value);
    setCurrentChannel(undefined);
    navigate('/messenger');
    setIsCopilotPanelOpen(false);
  };

  const dispatch = useAppDispatch();

  const loggedInMember = useAppSelector(userSelector());

  const handleChannelOnClick = (channel: TChannel) => {
    navigate(`/messenger/${channel.id}`);
  };

  const contextType = ClientContext;
  const { clientObject } = useContext(contextType);
  const { streamChatClient } = clientObject;

  const isMemberChannel = (channelId: string) => channelId.includes('members');

  const freeChannelListeners = () => {
    channelListeners.current.forEach(listener => listener.unsubscribe());
    channelListeners.current = [];
  };

  const queryForChannel = useCallback(
    async (channelId: string) => {
      const sort: ChannelSort = [{ last_updated: -1 }];
      const filter = {
        id: { $eq: channelId },
      };
      const channels = await streamChatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
      });
      return channels[0];
    },
    [streamChatClient],
  );

  const handleNewGuestMessage = useCallback(
    async (currentChannel: TChannel<DefaultGenerics>) => {
      const copilotReservationInformation = await fetchReservation(+companyId, +reservationId);

      if (!copilotReservationInformation || !currentChannel?.state?.messages) return;

      setIsCopilotPanelOpen(true);

      const { guest_portal, ...reservationData } = copilotReservationInformation;

      copilotPanelRef.current?.getInstantReply({
        reservationData: JSON.stringify(reservationData),
        guidesData: JSON.stringify(guest_portal),
        conversationData: JSON.stringify(currentChannel?.state?.messages),
      });
    },
    [companyId, reservationId],
  );

  const handleLastChannelReply = useCallback(
    (channel: TChannel<DefaultGenerics>) => {
      const lastMessage = channel?.state?.messages?.[channel?.state?.messages?.length - 1];

      if (lastMessage?.user?.id?.includes('rid')) {
        handleNewGuestMessage(channel);
        return;
      }

      copilotPanelRef.current?.resetInstantReply();
    },
    [handleNewGuestMessage],
  );

  useEffect(() => {
    dispatch(getCurrentMember());
    dispatch(getMessengerSetting());
  }, [dispatch]);

  useEffect(() => {
    const isNew = channelId?.includes('members-new');

    if (isNew) {
      const { state } = location;

      dispatch(createMemberChannel([+state.assigneeId, loggedInMember.user.id])).then(
        newChannelId => {
          navigate(newChannelId.replace('messaging:', ''));
        },
      );

      return;
    }

    if (channelId !== undefined && channelId !== previousChannelId && streamChatClient?.user?.id) {
      queryForChannel(channelId)
        .then(data => {
          freeChannelListeners();
          handleLastChannelReply(data);
          setCurrentChannel(data);

          if (isMemberChannel(channelId)) {
            setFilterIndex(MessageFilterIndex.MEMBERS);
          } else {
            setFilterIndex(MessageFilterIndex.GUESTS);
          }

          const chEventListener = data?.on(chatEvent => {
            if (chatEvent.type === 'message.new') {
              if (chatEvent.user?.id?.includes('mid')) {
                const reservationId = channelId?.split('-')?.[1];
                const chatMessage = chatEvent?.message?.text;

                if (reservationId && chatMessage) {
                  dispatch(notifyGuestAction(reservationId, chatMessage));
                }
              }

              if (chatEvent.user?.id?.includes('rid')) {
                handleNewGuestMessage(data);
              }
            }
          });

          channelListeners.current.push(chEventListener);
          data.markRead();
        })
        .catch(err => OpertoLogger.Log(err));
    }

    if (channelId !== previousChannelId) setPreviousChannelId(channelId);

    return () => {
      freeChannelListeners();
    };
  }, [
    location,
    loggedInMember.getStreamUser.id,
    channelId,
    previousChannelId,
    streamChatClient?.user?.id,
    dispatch,
    queryForChannel,
    handleLastChannelReply,
    navigate,
    loggedInMember.user.id,
    handleNewGuestMessage,
  ]);

  if (!streamChatClient?.user?.id) return null;

  let guestFilters: ChannelFilters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    channel_data: 'guests',
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };
  if (searchValue) {
    guestFilters = {
      ...guestFilters,
      $or: [
        { name: { $autocomplete: searchValue } },
        { property_name: { $autocomplete: searchValue } },
        { property_id: { $eq: searchValue } },
      ],
    };
  }

  const memberFilters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    channel_data: 'members',
    name: searchValue ? { $autocomplete: searchValue } : undefined,
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };

  const addChipFilters = (filters: object) => {
    let chipFilters = cloneDeep(filters);

    if (selectedChips.includes('Unread')) {
      chipFilters = { ...chipFilters, has_unread: true };
    }
    if (selectedChips.includes('Favourites')) {
      chipFilters = { ...chipFilters, favourite: true };
    }

    return chipFilters;
  };

  const handleFilterToggle = (filterIndex: MessageFilterIndex) => {
    if (filterIndex === MessageFilterIndex.GUESTS) {
      return addChipFilters(guestFilters);
    } else {
      return addChipFilters(memberFilters);
    }
  };

  let upperTabItems;

  if (featuresSelector.features?.mp_guest_portal_onboarding_enabled) {
    upperTabItems = [
      { label: labelName.GUESTS, value: MessageFilterIndex.GUESTS, isDropDown: false },
      { label: labelName.MEMBERS, value: MessageFilterIndex.MEMBERS, isDropDown: false },
      {
        label: labelName.SETTINGS,
        value: MessageFilterIndex.SETTINGS,
        isDropDown: false,
        icon: <SettingsIcon />,
      },
    ];
  } else {
    upperTabItems = [
      { label: labelName.MEMBERS, value: MessageFilterIndex.MEMBERS, isDropDown: false },
      {
        label: labelName.SETTINGS,
        value: MessageFilterIndex.SETTINGS,
        isDropDown: false,
        icon: <SettingsIcon />,
      },
    ];
  }

  const handleSearch = (search: string) => {
    setSearchValue(search);
  };

  const handleCreateTaskPanelClose = () => {
    setShowAddTaskPanel(false);
    setTaskId(undefined);
  };

  const chipFilters = ['Unread', 'Favorites'];

  const onCloseDrawer = () => {
    handleNewChat(false);
  };

  const handleInsertReply = (reply: string) => {
    if (!textareaRef.current) return;

    let newValue = textareaRef.current.value;

    if (newValue) newValue += '\n_______________________\n';

    newValue += reply;

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value',
    ).set;

    nativeInputValueSetter.call(textareaRef.current, newValue);
    const event = new Event('input', { bubbles: true });
    textareaRef.current.dispatchEvent(event);
  };

  const handleSendReply = (reply: string) => {
    currentChannel.sendMessage({
      text: reply,
    });

    copilotPanelRef.current?.resetInstantReply();
  };

  const handleCopilotButtonClick = () => {
    if (!isCopilotPanelOpen) {
      setIsCopilotPanelOpen(true);
      handleLastChannelReply(currentChannel);
    }
  };

  const handleOnSendMessage = () => {
    copilotPanelRef.current?.resetInstantReply();
  };

  const onTaskClick = (row?: GridRowParams<Task>): void => {
    setTaskId(row.id.toString());
    setShowAddTaskPanel(true);
  };

  const onNewTaskClick = () => {
    setTaskId(undefined);
    setShowAddTaskPanel(true);
  };

  const renderDesktopHeader = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: theme => `1px solid ${theme.palette.divider}`,
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            padding: '12px 16px 12px 16px',
          }}
        >
          <Typography variant='h4'>
            {`${filterIndex === MessageFilterIndex.GUESTS ? 'Guest' : 'Member'} messages`}
          </Typography>

          {isMessengerEnabled && (
            <FabIcon
              icon={<EditIcon />}
              size='small'
              color='primary'
              ariaLabel='add'
              onClick={() => handleNewChat(true)}
            />
          )}
        </Box>

        <Box sx={{ paddingLeft: '8px', paddingRight: '15px' }}>
          <CustomSearchAndFilter
            onSelectedChipsChange={onSelectedChipsChange}
            selectedChips={selectedChips}
            onSearchChange={handleSearch}
            searchValue={searchValue}
            chipFilters={chipFilters}
            showFullSearchBar={showFullSearchBar}
            onSearchIconClick={(value: boolean) => {
              setShowFullSearchBar(value);
            }}
            showMessagesTitle={(value: boolean) => {
              setShowMessagesTitle(value);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderMobileAndTabletHeader = () => {
    return (
      <Box
        sx={{
          padding: '12px 16px',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {showMessagesTitle && (
            <Box>
              <Typography variant='subtitle1'>
                {isTablet && 'All messages'}
                {isMobile &&
                  `${filterIndex === MessageFilterIndex.GUESTS ? 'Guest' : 'Member'} messages`}
              </Typography>
            </Box>
          )}
          <Box sx={{ width: !showMessagesTitle ? '100%' : 'inherit' }}>
            <CustomSearchAndFilter
              onSelectedChipsChange={onSelectedChipsChange}
              selectedChips={selectedChips}
              onSearchChange={handleSearch}
              searchValue={searchValue}
              chipFilters={chipFilters}
              showFullSearchBar={showFullSearchBar}
              onSearchIconClick={(value: boolean) => {
                setShowFullSearchBar(value);
              }}
              showMessagesTitle={(value: boolean) => {
                setShowMessagesTitle(value);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  if (channelId?.includes('members-new')) {
    return <LoadingContainer loading={true} />;
  }

  return (
    <>
      <Box marginRight={showAddTaskPanel ? '500px' : 0}>
        {!isMobileChatOpened && (
          <TabBar tabItems={upperTabItems} tabIndex={filterIndex} onTabChange={setTabIndex} />
        )}

        {isMessengerEnabled && (
          <Stack sx={{ flexDirection: 'row', overflow: 'hidden', height: 'calc(100vh - 240px)' }}>
            <Paper
              sx={{
                width: '100%',
                boxShadow: !isDesktop && 'none',
                borderTopRightRadius: isCopilotPanelOpen ? '0px' : '16px',
                borderBottomRightRadius: isCopilotPanelOpen ? '0px' : '16px',
              }}
            >
              {!isDesktop && !isMobileChatOpened && renderMobileAndTabletHeader()}
              <Stack direction='row' height='100%'>
                <Chat client={streamChatClient}>
                  <ChannelMessagesList
                    previousChannelId={previousChannelId}
                    currentChannel={currentChannel as TChannel<DefaultStreamChatGenerics>}
                    handleNewChat={handleNewChat}
                    handleChannelOnClick={handleChannelOnClick}
                    handleFilterToggle={handleFilterToggle}
                    filterIndex={filterIndex}
                  >
                    {isDesktop && renderDesktopHeader()}
                  </ChannelMessagesList>

                  <ChatMessages
                    currentChannel={currentChannel as TChannel<DefaultStreamChatGenerics>}
                    setCurrentChannel={setCurrentChannel}
                    handleNewChat={handleNewChat}
                    handleChannelOnClick={handleChannelOnClick}
                    isNewChat={isNewChat}
                    filterIndex={filterIndex}
                    isMessengerEnabled={isMessengerEnabled}
                    onCopilotButtonClick={handleCopilotButtonClick}
                    ref={textareaRef}
                    onSendMessage={handleOnSendMessage}
                    copilotButtonDisabled={!isGuestTab}
                    onTaskClick={onTaskClick}
                    onNewTaskClick={onNewTaskClick}
                  />

                  {isNewChat && !isDesktop && (
                    <SwipeableDrawer anchor='bottom' onClose={onCloseDrawer}>
                      <MessagePeopleListChatContainer
                        onNewChat={handleNewChat}
                        peopleType={filterIndex}
                        user={loggedInMember}
                        lastClickedOnChannel={handleChannelOnClick}
                      />
                    </SwipeableDrawer>
                  )}
                </Chat>
              </Stack>
            </Paper>

            {isCopilotEnabled && (
              <CopilotPanel
                isOpen={isCopilotPanelOpen}
                onClose={() => setIsCopilotPanelOpen(false)}
                onInsertReply={handleInsertReply}
                onSendReply={handleSendReply}
                ref={copilotPanelRef}
              />
            )}
          </Stack>
        )}

        {filterIndex === MessageFilterIndex.SETTINGS && <SettingsTabPage active />}

        {showFloatingFab && (
          <FabIcon
            icon={<EditIcon />}
            size='large'
            color='primary'
            ariaLabel='add'
            onClick={() => handleNewChat(true)}
            styles={{ position: 'fixed', bottom: '16px', right: '24px', zIndex: 1000 }}
          />
        )}
      </Box>

      {showAddTaskPanel && (
        <TasksDetails
          taskId={taskId}
          handleClose={handleCreateTaskPanelClose}
          onTaskUpdate={handleTaskUpdate}
        />
      )}
    </>
  );
};

export default MessagingPage;
