import { createApiClient } from '@operto/payments-shared';
import { ZodiosHooks } from '@zodios/react';

export const {
  useCreateTransaction,
  useUpdateTransactionDetails,
  useGetCompanyContext,
  useGetTransactionDetails,
  useGetCurrencies,
  useUpdateCompanyContext,
} = new ZodiosHooks('offers', createApiClient(process.env.REACT_APP_NEO_REST_API));
