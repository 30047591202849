import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, Fab, Menu, MenuItem, Stack, SxProps, Typography } from '@mui/material';
import { useMedia } from '@operto/ui';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import { ExportFormat } from 'helper/helper';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import TableTooltip from './TableTooltip';

export type SearchToolbarProps = {
  title: string | React.ReactNode;
  value?: string;
  onChange: (search: string) => void;
  onAdd?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onExport?: (format: ExportFormat) => void;
  disableAdd?: boolean;
  containerSx?: SxProps;
  addTooltipValue?: string;
  additionalComponents?: React.ReactNode;
};

export const SearchToolbar = ({
  title,
  value,
  onChange,
  onAdd,
  onExport,
  disableAdd = false,
  containerSx,
  addTooltipValue,
  additionalComponents,
}: SearchToolbarProps) => {
  const { isMobile } = useMedia();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleExportClick(format: ExportFormat) {
    if (onExport) {
      onExport(format);
      handleClose();
    }
  }

  return (
    <Stack sx={containerSx ?? searchBarContainer(isMobile)}>
      {!isMobile &&
        (typeof title === 'string' ? <Typography variant='subtitle1'>{title}</Typography> : title)}

      {additionalComponents}

      <Box data-testid='spacer' flex={1} />
      {onExport && (
        <Box sx={exportContainerStyle}>
          <Button
            id='export-button'
            aria-controls={open ? 'export-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='outlined'
            startIcon={<FileUploadOutlinedIcon />}
            size='large'
            sx={exportButtonStyle}
          >
            {t('export')}
          </Button>
          <Menu
            id='export-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'export-button',
            }}
          >
            <MenuItem sx={menuItemStyle} onClick={() => handleExportClick('csv')}>
              CSV
            </MenuItem>
            <MenuItem sx={menuItemStyle} onClick={() => handleExportClick('xml')}>
              XML
            </MenuItem>
          </Menu>
        </Box>
      )}
      <SearchTextField value={value} size='medium' onChange={onChange} data-testid='search-field' />
      {onAdd &&
        (disableAdd ? (
          <TableTooltip title={'Add restricted'}>
            <Box>
              <Fab disabled size='small' color='primary' sx={addButtonStyle}>
                <AddIcon />
              </Fab>
            </Box>
          </TableTooltip>
        ) : (
          <TableTooltip title={addTooltipValue}>
            <Fab size='small' color='primary' onClick={onAdd} sx={addButtonStyle}>
              <AddIcon />
            </Fab>
          </TableTooltip>
        ))}
    </Stack>
  );
};

const searchBarContainer = (isMobile: boolean): SxProps => ({
  paddingY: isMobile ? 1 : 2,
  paddingX: isMobile ? 1 : 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 1,
});

const addButtonStyle: SxProps = {
  marginLeft: 1,
};

const exportContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: 1,
};

const exportButtonStyle: SxProps = {
  textTransform: 'none',
  borderRadius: 2,
  fontWeight: 700,
};

const menuItemStyle: SxProps = {
  width: 200,
};
