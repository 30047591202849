import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, List, Popover, SxProps, Theme, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FilterDataType, FilterGroup } from 'types/filter-type';
import { CheckboxOption } from './CheckboxOption';
import { FiltersToolbarProps } from './FiltersToolbar';
import { SlideDownOption } from './SlideDownOption';

export type FilterChangeEvent = {
  key: string;
  value: string;
  checked: boolean;
  singleSelect?: boolean;
};

export const isSelected = (value: string, current?: string) => {
  const currentValue = current?.split(',') ?? [];
  return currentValue.includes(value);
};

export type FilterPanelProps = Omit<FiltersToolbarProps, 'filterGroups' | 'onFilterClearAll'> & {
  filter: FilterGroup;
};

export const FilterPanel = ({
  filter,
  searchParams,
  onFilterChange,
  onFilterClear,
  isClearableFilters,
  updateChipLabel,
}: FilterPanelProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const chipRef = useRef();

  const handleChange = ({ key, value, checked, singleSelect }: FilterChangeEvent) => {
    const currentValue = searchParams.get(key)?.split(',') ?? [];
    let updated: string[] = []; // no value
    if (!singleSelect) {
      if (checked) {
        updated = [...currentValue, value]; // append value
      } else {
        updated = currentValue.filter(val => val !== value); // remove value
      }
    } else if (checked) {
      updated = [value]; // replace current value
    }

    if (updated.length === 0) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, updated.toString());
    }

    onFilterChange(searchParams);
  };

  return (
    <>
      <Chip
        ref={chipRef}
        key={filter.groupName}
        label={
          updateChipLabel(filter.groupName) !== ''
            ? updateChipLabel(filter.groupName)
            : filter.label
        }
        variant='outlined'
        sx={filterChipStyle(updateChipLabel(filter.groupName) !== '')}
        onClick={() => setIsFilterOpen(true)}
        onDelete={() => setIsFilterOpen(true)}
        deleteIcon={
          <ArrowDropDownIcon sx={chipArrowStyle(updateChipLabel(filter.groupName) !== '')} />
        }
        data-testid={`${filter.groupName}-filter-button`}
      />
      <Popover
        id={`popover-${filter.groupName}`}
        open={isFilterOpen}
        anchorEl={chipRef.current}
        onClose={() => setIsFilterOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverStyle(false)}
        data-testid={`${filter.groupName}-popover`}
      >
        <Box sx={popoverHeaderStyle}>
          <Typography sx={popoverTitleStyle}>{filter.label}</Typography>
          <CloseIcon sx={closeIconStyle} onClick={() => setIsFilterOpen(false)} />
        </Box>
        <List sx={listStyle}>
          {filter.options.map((subOption: FilterDataType, index: number) => {
            switch (subOption.filterType) {
              case 'checkbox':
                return (
                  <CheckboxOption
                    key={`${filter.groupName}-${subOption.name}-${subOption.filterType}`}
                    testID={`${filter.groupName}-${subOption.name}-${subOption.filterType}`}
                    option={subOption}
                    checked={isSelected(subOption.name, searchParams.get(filter.groupName))}
                    onChange={checked =>
                      handleChange({
                        key: filter.groupName,
                        checked,
                        value: subOption.name,
                        singleSelect: subOption.singleSelect,
                      })
                    }
                  />
                );
              case 'slidedown':
                return (
                  <SlideDownOption
                    key={`${subOption.name}-${subOption.filterType}`}
                    isOpen={index === 0}
                    showDivider={index < filter.options.length - 1}
                    searchParams={searchParams}
                    option={subOption}
                    onChange={(checked, option) =>
                      handleChange({
                        key: subOption.name,
                        checked,
                        value: option.name,
                        singleSelect: subOption.singleSelect,
                      })
                    }
                  />
                );
            }
          })}
        </List>
        <Box sx={buttonWrapperStyle}>
          <Button
            variant='text'
            onClick={() => onFilterClear(filter.groupName)}
            sx={clearButtonStyle(isClearableFilters(filter.groupName))}
          >
            Clear
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export const popoverStyle = (isDatePicker: boolean): SxProps => ({
  '& .MuiPopover-paper': {
    borderRadius: '12px',
    padding: isDatePicker ? '0 24px 16px 24px' : '24px',
    minWidth: '320px',
    width: 'fit-content',
  },
});

const popoverHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const popoverTitleStyle: SxProps = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  color: '#08163E',
};

export const buttonWrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const dateButtonWrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const todayButtonStyle: SxProps = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '24px',
  color: 'primary',
  textTransform: 'capitalize',
  padding: '6px 16px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const clearButtonStyle = (isActive: boolean) => ({
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '22px',
  color: isActive ? '#155EFF' : '#00000042',
  textTransform: 'capitalize',
  padding: '0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const closeIconStyle: SxProps = {
  color: 'grey',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const chipArrowStyle = (isActive: boolean): SxProps => ({
  color: isActive ? '#155EFF !important' : 'text.primary !important',
});

export const filterChipStyle = (isActive: boolean): SxProps => ({
  backgroundColor: isActive ? 'primary.p8' : 'text.white',
  color: isActive ? '#155EFF' : 'text.primary',
  borderColor: isActive ? 'text.white' : '#08163E3B',
});

export const listStyle: SxProps<Theme> = {
  maxHeight: '350px',
  marginY: '12px',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    width: '0px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '2px',
  },
  '::-webkit-scrollbar-thumb:hover': theme => ({
    background: theme.palette.divider,
  }),
};
