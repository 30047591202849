import { Stack, SxProps } from '@mui/material';
import React from 'react';
import CopilotReply from './CopilotReply';

export default function CopilotReplies({
  replies,
  onInsertClick,
  onSendClick,
}: CopilotRepliesProps) {
  return (
    <Stack sx={bodyContainer}>
      {replies.map((reply, index) => {
        return (
          <CopilotReply
            key={`instant-reply-${index}`}
            reply={reply}
            order={index + 1}
            onInsertClick={reply => onInsertClick(reply)}
            onSendClick={reply => onSendClick(reply)}
          />
        );
      })}
    </Stack>
  );
}

type CopilotRepliesProps = {
  replies: string[];
  onInsertClick: (reply: string) => void;
  onSendClick: (reply: string) => void;
};

const bodyContainer: SxProps = {
  padding: '16px',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'auto',
  flex: 1,
  width: '100%',
};
