import { SendOutlined } from '@mui/icons-material';
import { Alert, Box, Typography } from '@mui/material';
import {
  LogDataType,
  logger,
  SendGridDeliveredStatuses,
  SendGridErrorStatuses,
  TwilioDeliveredStatuses,
  TwilioErrorStatuses,
  ValidationStatus,
} from '@operto/delivery-logs-shared';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { Buffer } from 'buffer';
import DOMPurify from 'dompurify';
import { utcToTimeZone } from 'helper/date';
import { toTitleCase } from 'helper/helper';
import useScheduling from 'hooks/useScheduling';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isAdmin } from 'redux/actions/ui';
import { ClearableEmailInput } from '../../ui-library/Components/input/ClearableEmailInput';
import { ClearablePhoneInput } from '../../ui-library/Components/input/ClearablePhoneInput';
import DeliveryLogsSendConfirmDialog from './DeliveryLogsSendConfirmDialog';

interface DeliveryLogsDetailsProps {
  log: LogDataType;
  handleClose: () => void;
}

const DeliveryLogsDetails = ({ log, handleClose }: DeliveryLogsDetailsProps) => {
  const { t } = useTranslation();

  const {
    channelType,
    guestName,
    recipient,
    scheduledAt,
    status,
    templateName,
    timezone,
    unitName,
    messageBody,
    deliveredAt,
    reservationId,
    templateId,
    unitId,
    id: messageId,
    jobId,
  } = log.row;

  const { handleSubmit } = useForm<LogDataType>({ mode: 'all' });
  const { snackbar } = useSnackbar();
  const { resendDeliveryLog } = useScheduling();
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendDialogRecipient, setSendDialogRecipient] = useState<string>('');
  const [isRecipientValid, setIsRecipientValid] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const isSms = channelType === 'sms';
  const channel = isSms ? t('sms') : t('email').toLowerCase();
  const title = t('send_placeholder_to', { placeholder: channel });

  const processedChannelType =
    channelType === 'email' ? toTitleCase(channelType) : channelType.toUpperCase();

  const isDelivered =
    SendGridDeliveredStatuses.includes(status) || TwilioDeliveredStatuses.includes(status);

  const isError = SendGridErrorStatuses.includes(status) || TwilioErrorStatuses.includes(status);

  const isPaused = status === ValidationStatus.FLOW_PAUSED;

  const isEmailInvalid =
    status === ValidationStatus.EMAIL_MISSING || status === ValidationStatus.EMAIL_INVALID;
  const isPhoneNumberInvalid =
    status === ValidationStatus.PHONE_NUMBER_MISSING ||
    status === ValidationStatus.PHONE_NUMBER_INVALID;

  const formattedScheduledAt = scheduledAt
    ? utcToTimeZone(scheduledAt, timezone, "MMMM dd, yyyy, 'at' h:mm a zzz")
    : '';

  const formattedDeliveredAt = deliveredAt
    ? utcToTimeZone(deliveredAt ?? '', timezone, "MMMM dd, yyyy, 'at' h:mm a zzz")
    : '';

  const getStatusTitle = () => {
    let title = '';
    let subtitle = t('failed_to_deliver_on_scheduled_date', { date: formattedScheduledAt });

    if (isDelivered) {
      title = t('successfully_delivered');
      subtitle = t('delivered_on_date', { date: formattedDeliveredAt });
    } else if (isError) {
      title = t('failed_to_deliver_with_status', {
        status: status === 'failed' ? '' : ` (${status})`,
      });
    } else if (isPaused) {
      title = t('message_wasnt_sent');
      subtitle = t('flow_was_paused');
    } else if (isEmailInvalid) {
      title = t('invalid_email');
    } else if (isPhoneNumberInvalid) {
      title = t('invalid_phone_number');
    } else {
      title = t('failed_to_deliver');
    }

    return { title, subtitle };
  };

  const processMessageBody = (messageBody: string, channelType: string) => {
    if (channelType === 'email') {
      return Buffer.from(messageBody, 'base64').toString('utf-8');
    }

    return messageBody;
  };

  const renderStatusAlert = () => {
    const { title, subtitle } = getStatusTitle();

    return (
      <>
        <Typography variant='body-lg-600'>{title}</Typography>
        <Typography variant='body2'>{subtitle}</Typography>
      </>
    );
  };

  const renderGuestDetailsBody = () => {
    return (
      <Box display='flex' flexDirection='column' alignItems='flex-start' gap='10px'>
        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('name')}
          </Typography>
          <Typography variant='body2'>{guestName}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('recipient')}
          </Typography>
          <Typography variant='body2'>{recipient}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {`${processedChannelType} ${t('template').toLowerCase()}`}
          </Typography>
          <Typography variant='body2'>{templateName}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('unit')}
          </Typography>
          <Typography variant='body2'>{unitName}</Typography>
        </Box>
      </Box>
    );
  };

  const handleSendClick = () => {
    setSendDialogRecipient(recipient || '');
    setSendDialogOpen(true);
  };

  const handleRecipientError = (isError: boolean) => {
    setIsRecipientValid(!isError);
  };

  const handleRecipientChange = (value: string) => {
    setSendDialogRecipient(value);
  };

  const handleSendDialog = async () => {
    setIsSending(true);

    const data = {
      messageId: messageId,
      recipient: sendDialogRecipient,
      scheduledAt: scheduledAt,
      channelType: channelType,
      reservationId: reservationId,
      templateId: templateId,
      guestName: guestName,
      unitId: unitId,
      jobId: jobId,
    };

    try {
      await resendDeliveryLog(data);

      setSendDialogOpen(false);
      setIsSending(false);
      snackbar(t('sent'));
      handleClose();
    } catch (error) {
      snackbar(t('error_sending'));
      setIsSending(false);
      logger.debug(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <FormContainer
        title={`${processedChannelType} ${t('delivery')}`}
        submitButtonTitle={t('send')}
        submitButtonEnabled={true}
        submitButtonIcon={<SendOutlined />}
        onClose={() => {
          handleClose();
        }}
        onSubmit={isAdmin() ? handleSubmit(handleSendClick) : undefined}
      >
        <Alert severity={isDelivered ? 'success' : 'error'} sx={alertStyles}>
          {renderStatusAlert()}
        </Alert>
        <AddCard title={<Typography variant='body-lg-600'>{t('guest_details')}</Typography>}>
          {renderGuestDetailsBody()}
        </AddCard>
        <AddCard
          title={
            <Typography variant='body-lg-600'>
              {isDelivered
                ? `${processedChannelType} ${t('delivered').toLowerCase()}`
                : `${processedChannelType} ${t('failed_to_deliver').toLowerCase()}`}
            </Typography>
          }
        >
          {channelType === 'email' ? (
            <Box
              style={{ height: '100%', overflow: 'scroll' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(processMessageBody(messageBody, channelType)),
              }}
            />
          ) : (
            <Box style={{ whiteSpace: 'pre-wrap' }}>
              {processMessageBody(messageBody, channelType)}
            </Box>
          )}
        </AddCard>
      </FormContainer>

      <DeliveryLogsSendConfirmDialog
        open={sendDialogOpen}
        onClose={() => {
          setSendDialogOpen(false);
        }}
        onSend={handleSendDialog}
        sendDisabled={isSending || !isRecipientValid}
        loading={isSending}
        title={title}
      >
        {isSms ? (
          <ClearablePhoneInput
            phoneValue={sendDialogRecipient}
            onPhoneChange={handleRecipientChange}
            onPhoneError={handleRecipientError}
          />
        ) : (
          <ClearableEmailInput
            emailValue={sendDialogRecipient}
            onEmailChange={handleRecipientChange}
            onEmailError={handleRecipientError}
          />
        )}
      </DeliveryLogsSendConfirmDialog>
    </>
  );
};

const alertStyles = {
  display: 'flex',
  padding: '6px 16px',
  marginBottom: '24px',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '4px',
};

export default DeliveryLogsDetails;
