import { SnackbarOrigin, SxProps } from '@mui/material';
import { IntegrationProvider } from 'integrations/integrationsTypes';
import { ConfirmFunctions } from './confimations';

export interface UiState {
  propertyId: number;
  memberProfile: MemberProfile;
  codeDetail: CodeDetail;
  showAddGroup: boolean;
  showAddTeamMember: boolean;
  showRefreshLoader: string;
  showMakeAvailableBanner: boolean;
  teamMemberType: string;
  showAddProperty: boolean;
  showAddIntercom: boolean;
  showEditIntercom: boolean;
  showAddGuestyIntegration: boolean;
  showAddHostawayIntegration: boolean;
  showAddMewsIntegration: boolean;
  showAddAvantioIntegration: boolean;
  showAddKigoIntegration: boolean;
  showAddVRSIntegration: boolean;
  showAddHostfullyIntegration: boolean;
  showAddDirectIntegration: boolean;
  showAddLightmakerIntegration: boolean;
  showSlidebar: string;
  showSnackbar: {
    [SnackbarTypes.OPEN]?: {
      message: string;
      variant: SnackbarVariant;
      anchorOrigin?: SnackbarOrigin;
      icon?: React.ReactNode;
      rootStyles?: SxProps;
      action?: React.ReactNode;
    };
    [SnackbarTypes.CLOSE]?: {
      message: string;
      variant: SnackbarVariant;
      anchorOrigin?: SnackbarOrigin;
      icon?: React.ReactNode;
      rootStyles?: SxProps;
      action?: React.ReactNode;
    };
  };
  showPanelSnackbar: {
    [SnackbarTypes.OPEN]?: {
      message: string;
      link?: string;
      type?: PanelSnackbarTypes;
      provider?: IntegrationProvider;
    };
    [SnackbarTypes.CLOSE]?: {
      message: string;
      link?: string;
      type?: PanelSnackbarTypes;
      provider?: IntegrationProvider;
    };
  };
  serviceNotifications: ServiceNotifications;
  propertyDetailId: number;
  propertyDetailTabIndex: number;
  activateUnits: ActivateUnits;
  addUnitToUnitGroup: boolean;
  activityFeed: boolean;
  addGroupsToMember: boolean;
  addUnitsToMember: boolean;
  addMembersToUnit: boolean;
  addGroupsToUnit: boolean;
  addNewReservation: boolean;
  moreUnits: MoreUnits;
  moreAlerts: MoreAlerts;
  moreFavorites: MoreFavorites;
  moreReservations: MoreReservations;
  moreCheckOuts: MoreCheckOuts;
  overviewData: OverviewData;
  confirmationModal: ConfirmationModal;
  temperatureSettings: boolean;
  showSidebar: boolean;
  showMemberProfileOptions: boolean;
  guestyPropertyImportComplete: string;
  cloudbedsPropertyImportComplete: string;
  myVRPropertyImportComplete: string;
  hostAwayPropertyImportComplete: string;
  mewsPropertyImportComplete: string;
  settingTadoTemperatureAStatus: string;
  refreshLoader: RefreshLoader;
  lockCommands: LockCommands;
  deleteBackupCode: DeleteBackupCode;
  showEditIntercomLoader: boolean;
  resetPassword: ResetPassword;
  retrievingMoreEvents: boolean;
  retrievingMoreEventFailed: boolean;
  makeAvailable: MakeAvailable;
  masterActivityFeed: boolean;
  favorites: Favorites;
  morePastAlerts: MorePastAlerts;
  viewPastUnitAlerts: boolean;
  netatmoFilterIndex: number;
  noiseAwareFilterIndex: number;
  showIntegrationsSlidebar: string;
}
export interface RefreshLoader {
  showRefreshLoader: boolean;
  previousMemberAccessCode: string;
  refreshFailed: boolean;
}
export interface MakeAvailable {
  showMakeAvailable: boolean;
  makeAvailableFailed: boolean;
  ids: number[];
  dismissedIds: number[];
  makeAvailableComplete: string;
}

export interface LockCommands {
  codeCommandStatus: string;
  previousLockStatus: boolean;
  propertyId: number;
  showLockCommandBanner: boolean;
}

export interface MoreUnits {
  show: boolean;
  unitIds: number[];
  title: string;
}

export interface MoreAlerts {
  show: boolean;
  unitIds: number[];
}
export interface MorePastAlerts {
  show: boolean;
  unitIds: number[];
}
export interface MoreFavorites {
  show: boolean;
  unitIds: number[];
}

export interface ConfirmationModal {
  show: boolean;
  ids: number[];
  functionType: ConfirmFunctions;
}

export interface MoreReservations {
  show: boolean;
  reservationIds: number[];
  title: string;
  showCodeStatus: boolean;
}

export interface MoreCheckOuts {
  show: boolean;
  reservationIds: number[];
  title: string;
  showCodeStatus: boolean;
}
export interface MemberProfile {
  show: boolean;
  id: number;
}

export interface CodeDetail {
  show: boolean;
  id: number;
}

export interface DeleteBackupCode {
  show: string;
  id: number;
}

export interface Favorites {
  show: string;
  id: number;
}

export interface ResetPassword {
  resetStatus: string;
  id: number;
}
export interface ServiceNotifications {
  show: boolean;
  notificationType: number;
}

export interface ActivateUnits {
  show: boolean;
  numberOfUnits: number;
}

export interface OverviewData {
  checkIns: number;
  confirmedCheckIns: number;
  checkOuts: number;
  confirmedCheckOuts: number;
  cleaning: number;
  confirmedCleaning: number;
}

export enum lockCommandStatus {
  pending = 'pending',
  success = 'success',
  failure = 'failure',
}

export enum SlidebarType {
  ASSIGN_MEMBERS_TO_UNITS = 'assign-members-to-units',
  ADD_NEW_PROPERTY = 'add-new-property',
  CLOSE = '',
  ADD_NEW_MEMBERS = 'add-new-members',
  ADD_NEW_LOCATION = 'add-new-location',
  ADD_NEW_RESERVATION = 'add-new-reservation',
  EDIT_MEMBERS = 'edit-members',
  EDIT_LOCATION = 'edit-location',
  EDIT_RESERVATIONS = 'edit-reservations',
  EDIT_INTERCOM = 'edit-intercom',
  EDIT_ACCESS_CODES = 'edit-access-codes',
  PROPERTY_EVENTS = 'property-events',
  CHECK_IN_RESERVATIONS = 'check-in-reservations',
  CHECK_OUT_RESERVATIONS = 'check-out-reservations',
  AVAILABLE_CLEANING = 'available-cleaning',
  SHOW_PROPERTY_MEMBERS = 'show-property-members',
  EDIT_CAMPAIGN = 'edit-campaign',
  SHOW_PROPERTY_ALERTS = 'property-alerts',
  LINK_MULTIPLE_ACCOUNTS = 'link-multiple-accounts',
  SEND_GP_LINK = 'send-gp-link',
  DELIVERY_LOGS_DETAILS = 'delivery-logs-details',
}

export enum IntegrationSlidebarType {
  PMS_HAPI = 'pms-hapi',
  PAYMENTS_STRIPE = 'payments-stripe',
  CLOSE = '',
}

export enum SnackbarTypes {
  CLOSE = 'close',
  OPEN = 'open',
}

export enum SnackbarVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
export interface SnackbarContentType {
  message: string;
  variant: 'success' | 'error' | 'info';
  anchorOrigin?: SnackbarOrigin;
  icon?: React.ReactNode;
  rootStyles?: SxProps;
  action?: React.ReactNode | null;
}
export interface SnackbarPanelContentType {
  message: string;
  link?: string;
  type?: string;
  provider?: IntegrationProvider;
}

export enum CodeTypes {
  BACKUP_CODES,
  TEAM_MEMBER_CODES,
  GUEST_CODES,
}

export enum PanelSnackbarTypes {
  CONNECT = 'connect',
  RESYNC = 'resync',
}
