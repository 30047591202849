import { Priority, Status, Task } from '@operto/tasks-shared';
import { trpc } from '@operto/trpc-client';

export const REFRESH_INTERVAL = 5 * 60 * 1000;

export default function useTasks({
  fetchOnMount = true,
  statusFilter = [],
  priorityFilter = [],
  assigneeFilter = [],
  dueDateFilter = [],
  requestDateFilter = [],
  assigneeId,
  refetchOnWindowFocus = true,
}: useTasksProps): useTaskReturnType {
  const {
    data,
    isFetching,
    isLoading,
    refetch: refetchTasks,
  } = trpc.tasks?.getTasks.useQuery(
    {
      status: statusFilter,
      priority: priorityFilter,
      assignee: assigneeFilter,
      dueDate: dueDateFilter,
      requestDate: requestDateFilter,
      assigneeId,
    },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
      refetchInterval: REFRESH_INTERVAL,
      refetchOnWindowFocus: refetchOnWindowFocus,
      enabled: fetchOnMount,
    },
  );

  const getTask = (taskId: string): getTaskProps => {
    const { data, isLoading, isFetching } = trpc.tasks.getTask.useQuery(
      {
        id: taskId,
      },
      { enabled: !!taskId, refetchOnWindowFocus: refetchOnWindowFocus },
    );

    return {
      isFetching,
      isLoading,
      data,
    };
  };

  const createTaskMutation = trpc?.tasks?.createTask?.useMutation();
  const createTask = async (task: Task) => {
    return await new Promise((resolve, reject) => {
      createTaskMutation?.mutate(task, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateTaskMutation = trpc?.tasks?.updateTask?.useMutation();
  const updateTask = async (task: Task) => {
    return await new Promise((resolve, reject) => {
      updateTaskMutation?.mutate(task, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  return {
    isFetching,
    isLoading,
    data,
    getTask,
    refetchTasks,
    createTask,
    updateTask,
  };
}

export const ALL_STATUSES = [
  'requested',
  'reviewed',
  'scheduled',
  'in progress',
  'completed',
  'closed',
  'declined',
  'canceled',
];
export const TODO_STATUS = ['requested', 'reviewed', 'in progress'];
export const NOT_STARTED_STATUS = ['requested', 'reviewed', 'scheduled'];
export const STARTED_STATUS = ['in progress'];
export const COMPLETED_STATUS = ['completed', 'closed', 'declined'];
export const CANCELED_STATUS = ['canceled'];
export const CLOSED_STATUS = ['completed', 'canceled', 'closed', 'declined'];

export type useTasksProps = {
  fetchOnMount?: boolean;
  statusFilter?: Status[];
  priorityFilter?: Priority[];
  assigneeFilter?: string[];
  dueDateFilter?: string[];
  requestDateFilter?: string[];
  assigneeId?: string;
  refetchOnWindowFocus?: boolean;
};

export type useTaskReturnType = {
  isFetching: boolean;
  isLoading: boolean;
  data?: Task[];
  getTask: (taskId: string) => getTaskProps;
  refetchTasks: () => void;
  createTask: (task: Task) => Promise<Task>;
  updateTask: (task: Task) => Promise<Task>;
};

export type getTaskProps = {
  isFetching: boolean;
  isLoading: boolean;
  data?: Task;
};
