import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ICompany, MessengerSetting } from 'company/companyType';
import { Actions } from 'types/actions';

export const initialAddressState = '';

const address: Reducer<string> = (state = initialAddressState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.address;
    default:
      return state;
  }
};

export const initialNameState = '';

const name: Reducer<string> = (state = initialNameState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.name;
    default:
      return state;
  }
};

export const initialEmailState = '';

const email: Reducer<string> = (state = initialEmailState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.email;
    default:
      return state;
  }
};

export const initialLogoUrlState = '';

const logoUrl: Reducer<string> = (state = initialLogoUrlState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.logo_url;
    default:
      return state;
  }
};

export const initialPhoneNumberState = '';

const phoneNumber: Reducer<string> = (state = initialPhoneNumberState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.phone_number;
    default:
      return state;
  }
};

export const initialWebsiteUrlState = '';

const websiteUrl: Reducer<string> = (state = initialWebsiteUrlState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.website_url;
    default:
      return state;
  }
};

const accessCodeFormat: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.member_pincode_format;
    default:
      return state;
  }
};

const accessCodeSetting: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.access_code_setting;
    default:
      return state;
  }
};

const id: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.storeToken:
      return action.companyId;
    default:
      return state;
  }
};

const code: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.company_code;
    default:
      return state;
  }
};

const guestPortalStatus: Reducer = (state = null, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.guest_portal_status;
    default:
      return state;
  }
};

const smwEnabled: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.smw_enabled;
    default:
      return state;
  }
};

const allMembersMigratedToCognito: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.all_members_migrated_to_cognito;
    default:
      return state;
  }
};

export const initialmessengerSetting: MessengerSetting = {
  mp_member_messenger_enabled: false,
  mp_guest_messenger_enabled: false,
  gp_smart_button_messenger_enabled: false,
  mp_instant_reply_enabled: false,
};

const messengerSetting: Reducer<MessengerSetting> = (state = initialmessengerSetting, action) => {
  switch (action.type) {
    case Actions.storeMessengerSetting:
      return action.messengerSetting;
    default:
      return state;
  }
};

export const testAccountEnabledState = false;
const testAccountEnabled: Reducer<boolean> = (state = testAccountEnabledState, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.test_account_enabled;
    default:
      return state;
  }
};

const smartButtonMessengerTriggers: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.warnToDisableSmartButtonTriggers:
      return true;
    case Actions.dismissWarnToDisableSmartButtonTriggers:
      return false;
    default:
      return state;
  }
};

const verificationProvider: Reducer<string> = (state = 'none', action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.verification_provider_type ?? 'none';
    default:
      return state;
  }
};

const roomTypeEnabled: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.room_type_enabled ?? false;
    default:
      return state;
  }
};

const newLoginEnabled: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.storeCompanyInfoFromServer:
      return action.company.new_login_enabled ?? false;
    default:
      return state;
  }
};

const reducer: Reducer<ICompany> = combineReducers<ICompany>({
  address,
  name,
  email,
  logoUrl,
  phoneNumber,
  websiteUrl,
  id,
  accessCodeFormat,
  code,
  guestPortalStatus,
  messengerSetting,
  accessCodeSetting,
  testAccountEnabled,
  smartButtonMessengerTriggers,
  verificationProvider,
  roomTypeEnabled,
  smwEnabled,
  allMembersMigratedToCognito,
  newLoginEnabled,
});

export default reducer;
