import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { GridSeparatorIcon } from '@mui/x-data-grid-pro';
import { paletteStyles, useMedia } from '@operto/ui';

import { LinkedAccountData, getLoggedInAccount } from 'auth/LinkedAccountsHelper';
import { ICompany } from 'company/companyType';
import React, { useEffect, useRef } from 'react';

interface LinkedAccountCompanySelectorProps {
  company: ICompany;
  drawerOpen: boolean;
  drawerWidth?: number;
  handleAccountClicked: (linkedAccount: LinkedAccountData) => void;
}

const LinkedAccountCompanySelector = ({
  company,
  drawerOpen,
}: LinkedAccountCompanySelectorProps) => {
  const { isMobile, isDesktop } = useMedia();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
  const linkedAccountContainerRef = useRef(null);

  const popoverOpen = Boolean(anchorEl);

  const toggle = () => {
    setAnchorEl(popoverOpen ? null : popoverAnchorEl);
  };

  useEffect(() => {
    if (!isDesktop) {
      const element = document.getElementById('operto-toolbar');
      setPopoverAnchorEl(element);
    } else {
      const element = document.getElementById('operto-content-section');
      setPopoverAnchorEl(element);
    }
  }, [drawerOpen, popoverAnchorEl, linkedAccountContainerRef.current?.offsetWidth, isDesktop]);

  return (
    <Box
      ref={linkedAccountContainerRef}
      onClick={() => isMobile && toggle()}
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          color='text.primary'
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '157%',
            letterSpacing: '0.1px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {getLoggedInAccount() && company.name}
          {!isMobile && ` - CID: ${company?.id}`}
        </Typography>
        <Typography
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              xl: 'none',
              lg: 'none',
            },
            color: 'text.secondary',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '166%',
            letterSpacing: '0.4px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {getLoggedInAccount()?.email}
        </Typography>
      </Box>
      <Box
        sx={{
          flexWrap: 'wrap',
          alignItems: 'center',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            xl: 'flex',
            lg: 'flex',
          },
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <GridSeparatorIcon
          fontSize='small'
          sx={{
            color: 'divider',
            height: '40px',
            display: 'flex',
          }}
        />
        <Typography
          sx={{
            color: 'text.secondary',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '166%',
            letterSpacing: '0.4px',
          }}
        >
          {getLoggedInAccount()?.email || 'Select Account'}
        </Typography>
      </Box>
    </Box>
  );
};

export interface LinkedAccountSelectorItemProps {
  linkedAccount: LinkedAccountData;
  handleAccountClicked: (linkedAccount: LinkedAccountData) => void;
}

export const LinkedAccountSelectorItem = ({
  linkedAccount,
  handleAccountClicked,
}: LinkedAccountSelectorItemProps) => {
  return (
    <ListItem
      sx={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'background.default01',
        },
        borderBottom: linkedAccount.isLoggedIn && `1px solid ${paletteStyles.palette.border}`,
        height: '56px',
        padding: '6px 16px',
        backgroundColor: 'inherit',
      }}
      key={`${linkedAccount.email}`}
      onClick={() => handleAccountClicked(linkedAccount)}
      secondaryAction={
        <>
          {linkedAccount.expired && (
            <Typography
              variant={'body1'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '400',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                lineHeight: '166%',
                letterSpacing: '0.15px',
                marginLeft: '8px',
                alignSelf: 'center',
                color: 'text.secondary',
              }}
            >
              {'Signed out'}
            </Typography>
          )}
        </>
      }
    >
      <ListItemText
        data-testid='linked-account-item'
        primary={
          <Box sx={{ width: '80%', display: 'flex', flex: 1, flexDirection: 'row' }}>
            <Typography
              variant={'body1'}
              sx={{
                width: '60%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: '400',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: 'text.primary',
              }}
            >
              {linkedAccount.companyName}
            </Typography>
            <Typography
              variant={'body1'}
              sx={{
                width: '40%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: '400',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: 'text.primary',
              }}
            >
              {linkedAccount.cid && ` - CID: ${linkedAccount.cid}`}
            </Typography>
          </Box>
        }
        secondary={
          <Typography
            variant='body1'
            style={{
              width: '70%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: '400',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontSize: '12px',
              lineHeight: '166%',
              letterSpacing: '0.4px',
              color: 'text.secondary',
            }}
          >
            {linkedAccount.email}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default LinkedAccountCompanySelector;
