import { createTasksOptions } from 'Pages/Tasks/utils';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import { useDeferredValue, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { DUE_DATE, REQUEST_DATE } from 'types/filter-type';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { useFilterLocalStorage } from './useFilterLocalStorage';
import { useSyncFilters } from './useSyncFilters';

const activeFilterKeys = [
  'task_status',
  'due_date',
  'request_date',
  'task_priority',
  'task_assignee',
];

export default function useTasksFilters(sortedMembers: IMember[]) {
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { getFilters, setFilters } = useFilterLocalStorage();
  const deferredMembers = useDeferredValue(sortedMembers);
  const key = 'tasks';
  const { syncStatus, searchParams, filterGroups, setSyncStatus, syncFilters, addFilterGroups } =
    useSyncFilters({
      key: `${loggedInMember.user.id}_${key}`,
      persistFilters: setFilters,
      hydrateFilters: getFilters,
    });

  const onFilterChange = (params: URLSearchParams) => {
    syncFilters(params);
  };

  const onFilterClear = (key: string) => {
    const isDefaultKey = activeFilterKeys.includes(key);
    if (isDefaultKey) {
      if (key === DUE_DATE || key === REQUEST_DATE) {
        searchParams.delete(`${key}_from`);
        searchParams.delete(`${key}_until`);
      } else {
        searchParams.delete(key);
      }
      searchParams.delete(key);
    }
    syncFilters(searchParams);
  };

  const isClearableFilters = (key: string) => {
    const isDefaultKey = activeFilterKeys.includes(key);
    if (isDefaultKey) {
      if (key === DUE_DATE || key === REQUEST_DATE) {
        return (
          searchParams.get(`${key}_from`)?.length > 0 ||
          searchParams.get(`${key}_until`)?.length > 0
        );
      } else {
        return searchParams.get(key)?.length > 0;
      }
    }
    return false;
  };

  const updateChipLabel = (filterGroup: string) => {
    let count = 0;
    let updatedLabel = '';

    if (filterGroup === DUE_DATE || filterGroup === REQUEST_DATE) {
      const startValue = searchParams.get(`${filterGroup}_from`);
      const endValue = searchParams.get(`${filterGroup}_until`);

      if (startValue) {
        updatedLabel = startValue;
      }

      if (endValue && startValue !== endValue) {
        updatedLabel += ` to ${endValue}`;
      }
    } else {
      const filterValueList = searchParams.get(filterGroup)?.split(',') ?? [];
      const selectedFilterGroup = filterGroups.find(
        filterOption => filterOption.groupName === filterGroup,
      );

      if (filterValueList.length > 0) {
        const selectedFilter = selectedFilterGroup?.options.find(
          option => filterValueList[0] === option.name,
        );
        updatedLabel = selectedFilter.label;
        let totalStatuses = filterValueList.length;
        count = --totalStatuses > 0 ? totalStatuses : 0;
      }
    }

    return updatedLabel !== '' ? `${updatedLabel} ${count > 0 ? `+${count}` : ''}` : '';
  };

  const updateTotalFilterLabelMobile = () => {
    const activeFilterCount = Array.from(searchParams.keys()).length;
    return activeFilterCount > 0 ? activeFilterCount.toString() : '';
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const createdFilterData = createTasksOptions(deferredMembers);
        addFilterGroups(createdFilterData);
      } catch (error) {
        logger.error('Tasks filter options failed, error: ', error);
        addFilterGroups([]);
      }
    }

    if (syncStatus === 'unsynched' && deferredMembers) {
      setSyncStatus('synching');
      if (key === 'tasks') {
        fetchData();
      } else {
        addFilterGroups([]);
      }
    }
  }, [addFilterGroups, key, searchParams, setSyncStatus, syncStatus, deferredMembers]);

  return {
    isSynched: syncStatus === 'synched',
    filterGroups,
    searchParams,
    onFilterChange,
    onFilterClear,
    isClearableFilters,
    updateChipLabel,
    updateTotalFilterLabelMobile,
  };
}
