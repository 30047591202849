import { ResendDeliveryLogType } from '@operto/delivery-logs-shared';
import { trpc } from '@operto/trpc-client';

export default function useScheduling() {
  const resendDeliveryLogMutation = trpc?.scheduling?.resendDeliveryLog?.useMutation();
  const resendDeliveryLog = async (resendDeliveryLogDto: ResendDeliveryLogType) => {
    return await new Promise((resolve, reject) => {
      resendDeliveryLogMutation?.mutate(resendDeliveryLogDto, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  return {
    resendDeliveryLog,
  };
}
