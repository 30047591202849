import { OpertoLogger } from 'Logger/logger';
import * as propertyApi from 'api/propertyAPI';
import * as reservationApi from 'api/reservationAPI';
import { ICode } from 'code/codeType';
import { IGuest, PaymentStatus } from 'guest/guestType';
import { getGuestsByPropertyAction } from 'guest/state/guestActions';
import { toggleSnackbar } from 'redux/actions/ui';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import {
  IReservation,
  ReservationDict,
  VERIFICATION_STATUS_TYPE,
} from 'reservation/reservationType';
import { Actions } from 'types/actions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { CodeDict } from './../../code/codeType';

export const getReservations = () => async (dispatch: AppDispatch) => {
  try {
    const result = await reservationApi.getReservations();
    const { reservations } = result.data.data;
    const reservationIds = reservations.map((each: IReservation) => each?.id);
    const reservationsById: ReservationDict = {};
    reservations.forEach((each: IReservation) => {
      reservationsById[each.id] = each;
    });
    return dispatch({
      type: Actions.addReservations,
      reservationsById,
      reservationIds,
    });
  } catch (err) {
    OpertoLogger.Log(err);
  }
};

export const getPropertyReservations = (propertyId: number) => (dispatch: AppDispatch) => {
  return propertyApi
    .getPropertyReservations(propertyId)
    .then(data => {
      const { records } = data.data.data;
      if (records) {
        const reservationIds: number[] = [];
        const reservationsById: ReservationDict = {};
        records.forEach((each: IReservation) => {
          if (each.status === 'confirmed' || each.status === 'blocked') {
            const { id } = each;
            reservationIds.push(id);
            reservationsById[id] = each;
          }
        });
        return dispatch({
          type: Actions.addReservations,
          reservationsById,
          reservationIds,
        });
      }
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const getPropertyReservationsCurrent = (propertyId: number) => (dispatch: AppDispatch) => {
  propertyApi
    .getPropertyReservationsCurrent(propertyId)
    .then(data => {
      const records = data.data.data;
      if (records) {
        const reservationIds: number[] = [];
        const reservationsById: ReservationDict = {};
        records.forEach((each: IReservation) => {
          if (each.status === 'confirmed' || each.status === 'blocked') {
            const { id } = each;
            reservationIds.push(id);
            reservationsById[id] = each;
          }
        });
        return dispatch({
          type: Actions.addReservations,
          reservationsById,
          reservationIds,
        });
      }
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const createReservation =
  (propertyId: number, reservation: IReservation) => async (dispatch: AppDispatch) => {
    const response = await reservationApi.createReservation({
      property_id: propertyId,
      guest_name: reservation.guest_name,
      guest_count: reservation.guest_count,
      guest_email: reservation.guest_email,
      guest_mobile: reservation.guest_phone,
      check_in: reservation.check_in,
      check_out: reservation.check_out,
    });

    const newReservation: IReservation = response.data.data;
    dispatch({
      type: Actions.insertReservation,
      reservation: newReservation,
    });
    dispatch(getReservationAccessCode(newReservation?.id));
    dispatch(getGuestsByPropertyAction(newReservation.property_id));
  };

export const updateReservation =
  (reservationId: number, reservation: IReservation) =>
  async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const response = await reservationApi.updateReservation(reservationId, {
      guest_name: reservation.guest_name,
      guest_count: reservation.guest_count,
      guest_email: reservation.guest_email,
      guest_mobile: reservation.guest_phone,
      check_in: reservation.check_in,
      check_out: reservation.check_out,
    });

    const updatedReservation = response.data.data;
    dispatch(getReservationAccessCode(reservation?.id));
    dispatch({
      type: Actions.upsertReservation,
      reservation: updatedReservation,
    });

    // NOTE: this is required to sync data coz of our messed up data structure
    const guest = getState().guests.byId[reservationId];
    const updatedGuest = {
      ...guest,
      name: reservation.guest_name,
      email: reservation.guest_email,
      phone: reservation.guest_phone,
      check_in: reservation.check_in,
      check_out: reservation.check_out,
    };
    dispatch({
      type: Actions.upsertGuest,
      guest: updatedGuest,
    });
  };

export const deleteReservation = (reservationId: number) => async (dispatch: AppDispatch) => {
  await reservationApi.deleteReservation(reservationId);
  dispatch({
    type: Actions.deleteReservation,
    id: reservationId,
  });
  dispatch({
    type: Actions.deleteGuest,
    id: reservationId,
  });
};

export const cancelPmsReservation =
  (reservationId: number) => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    await reservationApi.cancelPmsReservation(reservationId);
    dispatch({
      type: Actions.cancelPmsReservation,
      id: reservationId,
    });

    const state = getState();
    const guest = state.guests.byId[reservationId];
    dispatch({
      type: Actions.upsertGuest,
      guest: {
        ...guest,
        code_status: 'Blocked',
      },
    });
  };

export const grantPmsReservation =
  (reservationId: number) => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    await reservationApi.grantPmsReservation(reservationId);
    dispatch({
      type: Actions.grantPmsReservation,
      id: reservationId,
    });

    const getCodeStatus = async (): Promise<string> => {
      const result = await reservationApi.getReservationAccessCode(reservationId);
      const codes = result.data.data;
      const codeStatusList: string[] = codes.map((each: ICode) => each?.code_status);

      // Check for all of: Removed | Installed | Scheduled | Not Installed. Cannot be Blocked when regranting
      for (const status of ['Removed', 'Installed', 'Scheduled']) {
        if (codeStatusList.includes(status)) {
          return status;
        }
      }
      return 'Not Installed';
    };
    const code_status = await getCodeStatus();

    const state = getState();
    const guest = state.guests.byId[reservationId];
    dispatch({
      type: Actions.upsertGuest,
      guest: {
        ...guest,
        code_status: code_status,
      },
    });
  };

export const getReservationAccessCode = (reservationId: number) => (dispatch: AppDispatch) => {
  reservationApi.getReservationAccessCode(reservationId).then(data => {
    const codes = data.data.data;
    const codeList: number[] = [];
    const codeDict: CodeDict = {};
    codes.forEach((each: ICode) => {
      codeList.push(each.id);
      codeDict[each.id] = each;
    });
    dispatch({
      type: Actions.upsertCodes,
      codeList,
      codeDict,
    });
  });
};

export const updateGuestReservationVerification =
  (verified: boolean, param: IReservation | IGuest) => (dispatch: AppDispatch) => {
    return reservationApi
      .updateIncidentalPayment(param.id, verified)
      .then(data => {
        const updated = {
          ...param,
          incident_payment_verified: data.data.incidental_payment,
        };

        // NOTE: This is quite messed up we keep interchanging IReservation vs IGuest
        // GuestsTable.tsx passes IGuest to this method.
        // ReservationContentMain.tsx passes IReservation
        if ('guest_name' in param) {
          dispatch({
            type: Actions.upsertReservation,
            reservation: updated,
          });
        } else {
          dispatch({
            type: Actions.upsertGuest,
            guest: updated,
          });
        }
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

export const hideCodeDetailView = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.hideCodeDetailView,
  });
};

export const manuallyOverrideVerification =
  (reservationId: number) => (dispatch: AppDispatch, getState: () => ApplicationState) =>
    new Promise((resolve, reject) =>
      reservationApi
        .patchManuallyOverrideVerification(reservationId)
        .then(() => {
          const state = getState();
          const reservation = state.reservations.byId[reservationId];
          dispatch({
            type: Actions.upsertReservation,
            reservation: {
              ...reservation,
              verification_status: VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED,
            },
          });
          dispatch(
            toggleSnackbar(SnackbarTypes.CLOSE, {
              message: '',
              variant: SnackbarVariant.INFO,
            }),
          );
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Guest verification is manually confirmed.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
          resolve(true);
        })
        .catch(() => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'There was an error while manually confirming the guest verification.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          reject();
        }),
    );

export const manualOverrideSecurityDeposit =
  (reservationId: number, notes: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) =>
    new Promise((resolve, reject) =>
      reservationApi
        .patchSecurityDepositManual(reservationId, notes, PaymentStatus.MANUALLY_ACCEPTED)
        .then(() => {
          const state = getState();
          const reservation = state.reservations.byId[reservationId];
          const guest = state.guests.byId[reservationId];
          const newPaymentInfo = {
            ...(reservation || guest)?.payment_info,
            notes,
            status: PaymentStatus.MANUALLY_ACCEPTED,
          };
          dispatch({
            type: Actions.upsertReservation,
            reservation: {
              ...reservation,
              payment_info: newPaymentInfo,
            },
          });
          dispatch({
            type: Actions.upsertGuest,
            guest: {
              ...guest,
              payment_info: newPaymentInfo,
            },
          });
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Successfully accepted security override',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
          resolve(true);
        })
        .catch(err => {
          OpertoLogger.Log(err);
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'There was an error while manually accepting the security deposit',
              variant: SnackbarVariant.ERROR,
            }),
          );
          reject();
        }),
    );
