import CodeIcon from '@mui/icons-material/Code';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

const CurrentBranch = () => {
  return (
    <MenuItem sx={{ position: 'relative', bottom: 0, left: 0, display: 'flex', height: '50px' }}>
      <CodeIcon sx={{ marginRight: open ? '10px' : '0' }} />
      <Typography variant='body2'> {process.env.REACT_APP_GIT_BRANCH || 'N/A'}</Typography>
    </MenuItem>
  );
};

export default CurrentBranch;
