import { SxProps } from '@mui/material';
import { LogDataType } from '@operto/delivery-logs-shared';
import { Loading } from 'Common/Loading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType } from 'redux/units/types';
import { SlidebarType } from 'types/ui';
import {
  PaginatedTable,
  getSortModelByUrl,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import DeliveryLogsTitlebar from './DeliveryLogsTitlebar';
import useDeliveryLogFilters from './useDeliveryLogFilters';
import useDeliveryLogs from './useDeliveryLogs';
import useDeliveryLogsColumns from './useDeliveryLogsColumns';

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

const DeliveryLogsTable = () => {
  const firstLoad = useRef(true);
  const dispatch = useAppDispatch();
  const [searchString, setSearchString] = useState('');
  const deliveryLogsFilters = useDeliveryLogFilters();
  const columns = useDeliveryLogsColumns();
  const { data: units = INITIAL_STATE, isFetching: unitsFetching } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  //get the property ids from total properties as an array of string
  const propertyIds = units.units.map(unit => unit.id.toString());

  const {
    data: deliveryLogsData,
    isFetching,
    isLoading: isDeliverLogLoading,
  } = useDeliveryLogs(deliveryLogsFilters.searchParams, propertyIds);
  const [filteredLogs, setFilteredLogs] = React.useState<LogDataType>();

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredLogs(deliveryLogsData);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredData = deliveryLogsData?.filter(row => {
        return (
          row.guestName.toLowerCase().includes(lowerCaseSearchString) ||
          row.recipient?.toLowerCase().includes(lowerCaseSearchString) ||
          row.unitName?.toLowerCase().includes(lowerCaseSearchString) ||
          row.unitId?.toString().includes(lowerCaseSearchString) ||
          row.templateName?.toLowerCase().includes(lowerCaseSearchString)
        );
      });
      setFilteredLogs(filteredData);
    },
    [deliveryLogsData],
  );

  useEffect(() => {
    if (deliveryLogsData && filteredLogs === undefined) {
      setFilteredLogs(deliveryLogsData);
    }
  }, [deliveryLogsData, filteredLogs]);

  const onRowClick = (row: unknown) => {
    // Workaround to avoid console error when dispatching row:
    // ui.ts:190 A non-serializable value was detected in an action, in the path:
    // `content.delivery-logs-details.content.columns.0.sortComparator`
    const serializableRow = JSON.parse(JSON.stringify(row));
    dispatch(toggleSlidebar(SlidebarType.DELIVERY_LOGS_DETAILS, serializableRow));
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    // If searchParams (filters) changes, we need to handle search again
    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  if (firstLoad.current && (unitsFetching || isDeliverLogLoading)) {
    return <Loading />;
  }

  return (
    <>
      <DeliveryLogsTitlebar />
      <PaginatedTable
        enableToolbar
        rows={filteredLogs ?? []}
        columns={columns}
        filtersToolbarProps={deliveryLogsFilters}
        onSortModelChange={model =>
          deliveryLogsFilters.onFilterChange(
            sortTableByUrl(model, deliveryLogsFilters.searchParams),
          )
        }
        sortModel={getSortModelByUrl(deliveryLogsFilters.searchParams)}
        loading={!deliveryLogsFilters.isSynched || (isFetching && !deliveryLogsData)}
        onRowClick={onRowClick}
        sx={rowStyles}
        onFetch={handleSearch}
      />
    </>
  );
};

const rowStyles: SxProps = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
};

export default DeliveryLogsTable;
