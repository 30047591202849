import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { theme } from '@operto/ui';
import { SettingCard } from '@operto/ui-library';
import DisableMessengerDialog from 'Common/Dialog/DisableMessengerDialog';
import { MessengerSetting } from 'company/companyType';
import { getMessengerSetting, updateMessengerSetting } from 'company/state/companyAction';
import {
  messengerSettingSelector,
  smartButtonMessengerTriggersSelector,
} from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Actions } from 'types/actions';
import { userPermissionSelector } from 'user/state/userSelectors';

type SettingsListItemSwitchProps = {
  title: string;
  description: string;
  value: boolean;
  onChange: (value: boolean) => void;
  permission: boolean;
};
const SettingsListItemSwitch: React.FC<SettingsListItemSwitchProps> = props => {
  const { title, description, value, onChange, permission } = props;

  return (
    <ListItem>
      <ListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <Switch
          edge='end'
          onChange={(e, val) => onChange(val)}
          checked={value}
          disabled={!permission}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type SettingsCardProps = {
  settings: MessengerSetting;
  onSettingsChange: (setting: MessengerSetting) => void;
  hasGuestPortal?: boolean;
  sectionId?: string;
};
const SettingsCard: React.FC<SettingsCardProps> = props => {
  const { settings, onSettingsChange, hasGuestPortal, sectionId } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fetchGuidePages } = useGuestPortal();
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppFeatures();
  const featureCopilotEnabled = isFeatureEnabled('copilot');

  const smartButtonMessengerTriggers = useAppSelector(smartButtonMessengerTriggersSelector());

  const handleCloseDialog = () => {
    dispatch({ type: Actions.dismissWarnToDisableSmartButtonTriggers });
  };

  const goToPage = () => {
    navigate(`/guest-portal/guides/${sectionId}/pages`);
  };

  React.useEffect(() => {
    if (sectionId) fetchGuidePages(sectionId);
  }, [sectionId, fetchGuidePages]);

  return (
    <>
      <DisableMessengerDialog
        open={smartButtonMessengerTriggers}
        onClose={handleCloseDialog}
        goToPages={goToPage}
      />

      <Box sx={{ '& h6': theme.typography.h4 }}>
        <SettingCard title={t('operto_messenger')}>
          <Typography>{t('messenger_settings_description')}</Typography>

          <List>
            <SettingsListItemSwitch
              title='Guest Messenger'
              description='Enable or disable live messaging for your guests.'
              value={settings?.mp_guest_messenger_enabled ?? false}
              onChange={value =>
                onSettingsChange({ ...settings, mp_guest_messenger_enabled: value })
              }
              permission={hasGuestPortal}
            />

            <SettingsListItemSwitch
              title='Member Messenger'
              description='Enable or disable live messaging for your internal teams.'
              value={settings?.mp_member_messenger_enabled ?? false}
              onChange={value =>
                onSettingsChange({ ...settings, mp_member_messenger_enabled: value })
              }
              permission={hasGuestPortal}
            />

            <SettingsListItemSwitch
              title='Smart Button Messenger'
              description='Enable or disable Smart Messenger buttons in your Guide Pages.'
              value={settings?.gp_smart_button_messenger_enabled ?? false}
              onChange={value => {
                // !value && handleOpenDialog();
                onSettingsChange({ ...settings, gp_smart_button_messenger_enabled: value });
              }}
              permission={hasGuestPortal}
            />

            {featureCopilotEnabled && (
              <SettingsListItemSwitch
                title={t('settings_ai_instant_reply_title')}
                description={t('setting_ai_instant_reply_description')}
                value={settings?.mp_instant_reply_enabled ?? false}
                onChange={value => {
                  onSettingsChange({ ...settings, mp_instant_reply_enabled: value });
                }}
                permission={hasGuestPortal}
              />
            )}
          </List>
        </SettingCard>
      </Box>
    </>
  );
};

type SettingsProps = {
  active: boolean;
};
export const Settings: React.FC<SettingsProps> = props => {
  const dispatch = useAppDispatch();
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const { fetchGuideSections, sectionsList } = useGuestPortal();

  const setSettings = (setting: MessengerSetting) => {
    dispatch(updateMessengerSetting(setting));
  };
  React.useEffect(() => {
    dispatch(getMessengerSetting());
    fetchGuideSections();
  }, [dispatch, fetchGuideSections]);

  const hasGuestPortal = useAppSelector(userPermissionSelector());

  const { active } = props;
  if (!active) {
    return null;
  }

  return (
    <SettingsCard
      settings={messengerSetting}
      onSettingsChange={setSettings}
      hasGuestPortal={hasGuestPortal}
      sectionId={sectionsList[0]?.section_id}
    />
  );
};

export default Settings;
