import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

export type TaskSummaryCardProps = {
  [status: string]: number;
};

export const TaskSummaryCard = (props: { data: TaskSummaryCardProps }) => {
  const { data } = props;

  return (
    <Grid container gap='8px'>
      {Object.keys(data).map(status => (
        <Grid item flexBasis='123px' key={`summary-card-${status}`}>
          <Paper
            sx={{
              height: 56,
              width: 123,
              paddingX: 1,
              paddingY: 0.5,
              borderRadius: 1,
            }}
          >
            <Typography
              sx={{ fontSize: 18, fontWeight: 700 }}
              color={status === 'Overdue' ? 'error' : 'inherit'}
            >
              {data[status]}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 400 }}>{status}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
