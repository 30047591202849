import { Add } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box, IconButton, SxProps, Tab, Tabs, Theme, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { TitlebarButtonExpanded, useMedia } from '@operto/ui';
import FavouriteIcon from 'Common/Icons/FavouriteIcon';
import { companySelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useChatContext } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { updateFavouriteAction } from './MessagingActions';
import { TeamsTaskMenu } from './TeamsTasks/TeamsTaskMenu';

interface ChannelHeaderProps {
  onChevronClick?: () => void;
  showFavouriteIcon?: boolean;
  showUnitName?: boolean;
  onTaskTabClick?: (displayTaskTab: number) => void;
  activeTab: number;
  resID?: string;
  propertyName?: string;
  isGuestTab?: boolean;
  onNewTaskClick?: () => void;
}
const CustomChannelHeader = (props: ChannelHeaderProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const company = useAppSelector(companySelector());
  const { isMobile } = useMedia();
  const { channel } = useChatContext();
  const { name } = channel.data || {};

  const members = channel.data.member_count;
  const showFavouriteIcon = props?.showFavouriteIcon;
  const showUnitName = props?.showUnitName && isFeatureEnabled('tasks', company.id);
  const isGuestTab = props?.isGuestTab;
  const online = channel.state.watcher_count;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const favourite = (channel?.data?.favourite as boolean) ?? false;
  const captionInfo = {
    title: channel?.data?.['property_name'] as string,
    url: `/units/${channel?.data?.['property_id'] as number}`,
  };
  const onTaskTabClick = props?.onTaskTabClick;
  const onNewTaskClick = props?.onNewTaskClick;
  const activeTab = props.activeTab;
  const resID = props.resID;
  const propertyName = props.propertyName;
  const { t } = useTranslation();

  const mainContainerStyles: SxProps<Theme> = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    paddingTop: '12px',
    paddingLeft: isMobile ? 0 : '16px',
    paddingBottom: '12px',
    paddingRight: '24px',
    borderBottom: theme => `1px solid ${theme.palette.divider}`,
  };

  const truncatedTextStyle: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  const renderChevronIcon = () => {
    return (
      <IconButton
        sx={{ height: '48px', width: '48px' }}
        onClick={props.onChevronClick}
        data-testid='chevron-icon'
      >
        <ChevronLeftIcon sx={{ height: '100%', width: '100%' }} />
      </IconButton>
    );
  };

  let onClickFavouriteTimeout: NodeJS.Timeout;

  const onClickFavouriteIcon = async () => {
    await dispatch(updateFavouriteAction(channel.id, !favourite));
    clearTimeout(onClickFavouriteTimeout);
    onClickFavouriteTimeout = setTimeout(() => navigate(`/messenger/${channel.id}`), 300);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (captionInfo?.url) {
      navigate(captionInfo.url);
      e.stopPropagation();
    }
  };

  const renderMessageTabs = () => {
    return (
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => onTaskTabClick(newValue)}
        aria-label='message channel tabs'
      >
        <Tab label='Messages' value={0} />
        <Tab label='Tasks' value={1} />
      </Tabs>
    );
  };

  return (
    <>
      <Box sx={mainContainerStyles}>
        {isMobile && !isFeatureEnabled('hackfest', company.id) && renderChevronIcon()}
        <Avatar>
          <ProfileIcon
            imageUrl={channel.data?.image as string}
            size={'medium'}
            fullName={channel.data?.name}
          />
        </Avatar>

        <Box sx={{ flexGrow: 1, width: '118.5px' }}>
          <Typography variant='subtitle1' sx={truncatedTextStyle}>
            {name}
          </Typography>
          {isFeatureEnabled('tasks', company.id) && (resID || propertyName) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {propertyName && (
                <Typography variant='caption' sx={propertyNameStyling}>
                  {propertyName}
                </Typography>
              )}
              {resID && (
                <Typography variant='caption' sx={resIDStyling}>
                  {'Res ID: ' + resID}
                </Typography>
              )}
            </Box>
          )}
          <Typography variant='body2'>
            {members} members, {online} online
          </Typography>
          {isFeatureEnabled('tasks', company.id) && renderMessageTabs()}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            gap: '8px',
            marginBottom: 3,
          }}
        >
          {showUnitName && (
            <Link
              component='span'
              variant='caption2'
              color='primary'
              onClick={handleClick}
              sx={truncatedTextStyle}
            >
              {captionInfo?.title ? captionInfo.title : ''}
            </Link>
          )}
          {showFavouriteIcon && (
            <FavouriteIcon
              isFavourite={favourite}
              onClick={onClickFavouriteIcon}
              styles={{ padding: 0 }}
            />
          )}

          {isFeatureEnabled('tasks', company.id) && (
            <TitlebarButtonExpanded
              onClick={onNewTaskClick}
              style={{ borderRadius: '6px', padding: '8px 12px' }}
            >
              <Add />
              {t('task')}
            </TitlebarButtonExpanded>
          )}

          {isFeatureEnabled('hackfest', company.id) && isGuestTab && activeTab === 0 && (
            <TeamsTaskMenu />
          )}
        </Box>
      </Box>
    </>
  );
};

const propertyNameStyling: SxProps = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  textAlign: 'right',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: '#155EFF',
};

const resIDStyling: SxProps = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
};

export default CustomChannelHeader;
