import { setupAppFeatures } from '@operto/app-features';
import { isProduction } from './constants';

const NEO_GATEWAY_V2_URL = process.env.REACT_APP_NEO_GATEWAY_V2_URL;

// declare your app features here
const appFeatures = {
  churnzero: {
    enabled: isProduction,
  },
  fullstory: {
    enabled: isProduction,
  },
  groups: {
    enabled: true,
  },
  tags: {
    enabled: true,
  },
  guestTerms: {
    enabled: true,
  },
  guestProfile: {
    enabled: true,
  },
  transactionsPage: {
    enabled: false,
  },
  upsells: {
    enabled: false,
  },
  communications: {
    enabled: !isProduction,
  },
  deliveryLogs: {
    enabled: true,
  },
  locks: {
    enabled: !isProduction,
  },
  tasks: {
    enabled: !isProduction,
  },
  hackfest: {
    enabled: !isProduction,
    description: 'this feature is to add simple task requesting from the guest app',
    includedCompanies: [45136],
  },
  copilot: {
    enabled: !isProduction,
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const { useAppFeatures } = setupAppFeatures<AppFeatureFlags>(
  appFeatures,
  NEO_GATEWAY_V2_URL ? `${NEO_GATEWAY_V2_URL}/app-features/operations` : undefined,
);
